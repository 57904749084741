import React, { useState } from "react";
import CardLayout from "../components/common/CardLayout";
import DriverProfilesTable from "../components/driver-management/driver-profiles/DriverProfilesTable";
import { useDispatch, useSelector } from "react-redux";
import { updateDriverRegionFilter } from "../slices/regionSlice";
import { getFromLocalStorage } from "../utils/helpers";
import { DropdownSearchSingleSelect } from "../utils/forms/NewFormComponents";

const AllDriverManagement = () => {
  const regions = useSelector((state) => state.region?.mainRegions);
  const selectedDriverRegionFilters = useSelector(
    (state) => state.region?.driver
  );
  const dispatch = useDispatch();

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams: {},
  });

//   const onDriverRegionFilterChange = (e) => {
//     dispatch(updateDriverRegionFilter(e));
//     setDataState({
//       ...dataState,
//       skip: 0,
//       take: 10,
//       otherParams: {
//         region_id: e.regionId + ":in:string",
//       },
//     });
//   };
  const updatedRegions = regions?.slice()?.map((item, index) => ({
    ...item,
    label: item.child
      ? `${item.regionName} (${item.parentRegionName})`
      : item.regionName,
  }));

//   const DriverRegionFilterDiv = () => (
//     <div>
//       <DropdownSearchSingleSelect
//         options={updatedRegions}
//         textField="label"
//         onChange={onDriverRegionFilterChange}
//         value={selectedDriverRegionFilters}
//         backupMessage="No Options Available"
//         parentClass="tw-relative tw-w-64"
//       />
//     </div>
//   );

  return (
    <CardLayout
      title="Driver Management"
    //   ComponentRender={DriverRegionFilterDiv}
    >
      <DriverProfilesTable
        allData
        dataState={dataState}
        setDataState={setDataState}
      />
    </CardLayout>
  );
};

export default AllDriverManagement;
