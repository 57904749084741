import React, { useEffect, useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { useNavigate } from "react-router-dom";
import {
  CategoryFilterCell,
  driverStatusTags,
  getLabelFromEnum,
  initialFilter,
  stringToSentenceCase,
} from "../../../utils/helpers";
import GridLoader from "../../common/GridLoader";
import { APIConfig } from "../../../utils/constants/api.constants";
import { DriverConfig } from "../../../config/driverConfig";
import { useSelector } from "react-redux";
import FleetViewMap from "../../maps/FleetViewMap";
import TopFilters from "../../common/TopFilters";
import axiosService from "../../../init/axios";
import LoadingSpinner from "../../common/LoadingSpinner";

const DriverProfilesTable = ({ dataState, setDataState, allData = false }) => {
  const baseUrl =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.driverManagement.getAllDrivers;
  const navigate = useNavigate();
  const selectedDriverRegionFilters = useSelector(
    (state) => state.region?.driver
  );
  const [mapLoader, setMapLoader] = useState(false);
  const [filterArr, setFilterArr] = useState([]);
  const [statusFilter, setStatusFilter] = useState("All");
  const [driverProfiles, setDriverProfiles] = useState({ data: [], total: 0 });
  const [bounding, setBounding] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const columns = [
    {
      id: "driverId",
      field: "driverId",
      title: "Driver ID",
      filterable: true,
      filterKey: "driver_id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "fullName",
      field: "fullName",
      title: "Driver Name",
      filterable: true,
      filterKey: "full_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 210,
    },
    ...(allData
      ? [
          {
            id: "onboardingStage",
            field: "onboardingStage",
            title: "Onboarding Status",
            filterable: true,
            filterKey: "onboarding_stage",
            filterType: "string",
            defaultFilterOperator: "eq",
            data: DriverConfig.onboardingStage,
            width: 220,
          },
        ]
      : []),
    {
      id: "driverStatus",
      field: "driverStatus",
      title: "Status",
      filterable: false,
      width: 150,
      cell: (cell) => (
        <td
          colSpan={cell.colSpan}
          role="gridcell"
          aria-colindex={cell.ariaColumnIndex}
          aria-selected="false"
          datagridcolindex={cell.columnIndex}
        >
          {allData ? (
            <>{driverStatusTags(cell?.dataItem?.driverStatus)}</>
          ) : (
            <span
              className="tw-cursor-pointer"
              onClick={() => setSelectedDriver(cell?.dataItem)}
            >
              {driverStatusTags(cell?.dataItem?.driverStatus)}
            </span>
          )}
        </td>
      ),
    },
    {
      id: "mobileNumber",
      field: "mobileNumber",
      title: "Mobile Number",
      filterable: true,
      filterKey: "mobile_number",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    ...(allData
      ? [
          {
            id: "bgvStatus",
            field: "bgvStatus",
            title: "3rd Party BGV",
            filterable: true,
            filterKey: "bgv_status",
            filterType: "string",
            defaultFilterOperator: "eq",
            data: DriverConfig.bgvStatus,
            width: 200,
          },
        ]
      : []),
    ...(allData
      ? [
          {
            id: "testDriveStatus",
            field: "testDriveStatus",
            title: "Driving Test Status",
            filterable: true,
            filterKey: "test_drive_status",
            filterType: "string",
            defaultFilterOperator: "eq",
            data: DriverConfig.testDriveStatus,
            width: 200,
          },
        ]
      : []),
    {
      id: "driverPayoutType",
      field: "driverPayoutType",
      title: "Earnings Plan",
      filterable: true,
      filterKey: "driver_payout_type",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: DriverConfig.driverPayoutType,
      width: 200,
    },
    {
      id: "regionId",
      field: "regionId",
      title: "Region Id",
      filterable: false,
      width: 200,
    },
    {
      id: "lastLocation",
      field: "lastLocation",
      title: "Last Location",
      filterable: false,
      width: 300,
    },
    {
      id: "updatedAt",
      field: "updatedAt",
      title: "Updated At",
      filterable: false,
      width: 200,
    },
  ];

  const getFilterData = async () => {
    let filterBaseUrl;
    if (selectedDriverRegionFilters?.regionId && !allData) {
      filterBaseUrl =
        process.env.REACT_APP_DRIVER_BASE_URL +
        APIConfig.driverManagement.getDriverStats +
        "?region_id=" +
        selectedDriverRegionFilters?.regionId +
        encodeURI(":in:string") +
        "&onboarding_stage=ONBOARDING_FORM_COMPLETE:eq:string&bgv_status=SUCCESS:eq:string&test_drive_status=SUCCESS:eq:string";
    } else {
      filterBaseUrl =
        process.env.REACT_APP_DRIVER_BASE_URL +
        APIConfig.driverManagement.getDriverStats;
    }

    await axiosService
      .get(filterBaseUrl)
      .then((data) => {
        setFilterArr(data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    setStatusFilter("All");
    getFilterData();
    if (selectedDriverRegionFilters && !allData) {
      setDataState({
        ...dataState,
        otherParams: {
          region_id: selectedDriverRegionFilters.regionId + ":in:string",
          onboarding_stage: "ONBOARDING_FORM_COMPLETE:eq:string",
          bgv_status: "SUCCESS:eq:string",
          test_drive_status: "SUCCESS:eq:string",
        },
      });
    }
  }, [selectedDriverRegionFilters]);

  const dataStateChange = (e) => {
    getFilterData();

    const regionQueryStr = selectedDriverRegionFilters?.regionId;
    const otherParams = {
      ...(!allData &&
        regionQueryStr && {
          region_id: `${regionQueryStr}:in:string`,
          onboarding_stage: "ONBOARDING_FORM_COMPLETE:eq:string",
          bgv_status: "SUCCESS:eq:string",
          test_drive_status: "SUCCESS:eq:string",
        }),
    };
    if (statusFilter?.filters?.length > 0) {
      const [key, value] = statusFilter.filters[0].split("=");
      otherParams[key] = value;
    }
    setDataState(() => ({
      ...e.dataState,
      otherParams,
    }));
  };

  const dataReceived = (dataObj) => {
    const arr = dataObj.data.data.map((driver) => {
      const updatedProfileDetails = getLabelFromEnum(
        ["bgvStatus", "testDriveStatus", "onboardingStage", "driverPayoutType"],
        driver,
        "driver"
      );
      const fullName = driver?.fullName
        ? stringToSentenceCase(driver.fullName)
        : "";
      const regionId = driver?.regionIds?.join(", ") || "";
      const lastLocation = `${driver.lastKnownLat},${driver.lastKnownLng}`;

      return {
        ...updatedProfileDetails,
        fullName,
        regionId,
        lastLocation,
      };
    });

    setDriverProfiles({ ...dataObj, data: arr });
  };

  const onDriverRowClick = (item) => {
    navigate(
      `/home/driver/${item.dataItem.driverId}/profile?driverName=${item.dataItem.fullName}`
    );
  };

  const handleFilterClick = (filter) => {
    setStatusFilter(filter);
    const regionQueryStr = selectedDriverRegionFilters?.regionId;
    const otherParams = {
      ...(!allData &&
        regionQueryStr && {
          region_id: `${regionQueryStr}:in:string`,
          onboarding_stage: "ONBOARDING_FORM_COMPLETE:eq:string",
          bgv_status: "SUCCESS:eq:string",
          test_drive_status: "SUCCESS:eq:string",
        }),
      ...(bounding && {
        bounding: encodeURI(
          `${bounding.north},${bounding.south},${bounding.east},${bounding.west}`
        ),
      }),
    };
    if (filter?.filters?.length > 0) {
      const [key, value] = filter.filters[0].split("=");
      otherParams[key] = value;
    }
    setDataState((prevState) => ({
      ...prevState,
      otherParams,
    }));
  };

  const onBoundsChange = (e) => {
    if (allData) return;
    let bounds = e.toJSON();
    getFilterData();
    setBounding(bounds);
    const regionQueryStr = selectedDriverRegionFilters?.regionId;
    const otherParams = {
      ...(!allData &&
        regionQueryStr && {
          region_id: `${regionQueryStr}:in:string`,
          onboarding_stage: "ONBOARDING_FORM_COMPLETE:eq:string",
          bgv_status: "SUCCESS:eq:string",
          test_drive_status: "SUCCESS:eq:string",
        }),
      bounding: encodeURI(
        `${bounds.north},${bounds.south},${bounds.east},${bounds.west}`
      ),
    };
    if (statusFilter?.filters?.length > 0) {
      const [key, value] = statusFilter.filters[0].split("=");
      otherParams[key] = value;
    }
    setDataState({
      ...dataState,
      otherParams: {
        ...otherParams,
      },
    });
  };

  const handleMapLoader = (val) => {
    if (allData) return;

    setMapLoader(val);
  };
  return (
    <>
      <div className="tw-mb-5">
        {filterArr.data ? (
          <TopFilters
            statusFilter={statusFilter}
            handleFilterClick={handleFilterClick}
            filters={filterArr.data}
          />
        ) : (
          <div className="tw-flex tw-items-center tw-gap-0 tw-w-2/4 tw-h-10 tw-animate-pulse topFilter"></div>
        )}
      </div>
      <div className="tw-flex tw-gap-5 tw-h-full">
        <div style={{ width: allData ? "100%" : "60%" }}>
          <Grid
            filter={initialFilter}
            filterable
            sortable={false}
            pageable
            {...dataState}
            data={driverProfiles}
            onDataStateChange={dataStateChange}
            onRowDoubleClick={onDriverRowClick}
          >
            {columns.map((column) => (
              <Column
                key={column.id}
                field={column.field}
                title={column.title}
                width={column.width}
                filterable={column.filterable}
                filterCell={
                  column.defaultFilterOperator === "eq" &&
                  ((props) => CategoryFilterCell(props, column, 250))
                }
                cell={column.cell}
              />
            ))}
          </Grid>
          <GridLoader
            loader={handleMapLoader}
            baseUrl={baseUrl}
            columns={columns}
            dataState={dataState}
            onDataReceived={dataReceived}
          />
        </div>
        {!allData && (
          <div className="tw-w-full tw-bg-gray-200 tw-h-[80dvh] tw-relative">
            {mapLoader && (
              <div className="tw-grid tw-bg-black/20 tw-place-content-center tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-z-10">
                <LoadingSpinner />
              </div>
            )}
            <FleetViewMap
              setSelectedDriverTabel={setSelectedDriver}
              selectedDriverTable={selectedDriver}
              region={selectedDriverRegionFilters}
              data={driverProfiles.data}
              onBoundsChange={onBoundsChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default DriverProfilesTable;
