import React, { useCallback, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import ModalLayout from "../common/ModalLayout";
import DestinationAutoComplete from "../maps/DestinationAutoComplete";
import AddTaskMap from "../maps/AddTaskMap";
import AddTaskForm, { CarDetailsForm, TIME_SLOTS } from "./AddTaskForm";
import { DropdownSearchSingleSelect } from "../../utils/forms/NewFormComponents";
import { APIConfig } from "../../utils/constants/api.constants";
import axiosService from "../../init/axios";
import { showErrorMessage } from "../../utils/helpers";
import closeBtn from "../../assets/closeButton.svg";
// import LoadingSpinner from "../common/LoadingSpinner";
// import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import {
  numberRegex,
  phoneValidator,
  urlRegex,
} from "../../utils/forms/Validators";

// set buffer time for timeslots
const BUFFER_TIME = 2;

function getFormattedDate() {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const dd = String(today.getDate()).padStart(2, "0");
  const formattedDate = `${yyyy}-${mm}-${dd}`;
  return formattedDate;
}

const AddTaskModal = ({ handleModalClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedClient, setSelectedClient] = useState("");
  const navigate = useNavigate();
  const [address, setAddress] = useState({
    pickup: "",
    drop: "",
  });
  const [clients, setClients] = useState([]);
  const setAddressCallback = useCallback((newAddress) => {
    setAddress(newAddress);
  }, []);
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [checkListData, setCheckListData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formDetails, setFormDetails] = useState({
    pickupDetails: {
      customerName: "",
      customerPhoneNumber: "",
      customerSecondaryPhoneNumber: "",
      clientName: "",
      clientNumber: "",
      checklistId: "",
      date: getFormattedDate(),
      timeSlot: "",
      txnTimeMinutes: "",
      qcUrl: "",
    },
    dropDetails: {
      customerName: "",
      customerPhoneNumber: "",
      customerSecondaryPhoneNumber: "",
      clientName: "",
      clientNumber: "",
      checklistId: "",
      date: getFormattedDate(),
      timeSlot: "",
      txnTimeMinutes: "",
      qcUrl: "",
    },
    otherDetails: {
      expectedPrice: "",
      carType: "", //
      transmissionType: "", //
      registrationNumber: "", //
      fuelType: "", //
    },
  });

  const getClients = async () => {
    let baseUrl =
      process.env.REACT_APP_USER_BASE_URL + APIConfig.clients.getClients;

    await axiosService
      .get(baseUrl)
      .then((data) => {
        let newArr = convertClientsArray(data.data.clients);
        setClients(newArr);
        // setSelectedClient(newArr[0]);
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };

  function convertClientsArray(clients) {
    return clients.map((client) => ({
      value: client.clientId,
      label: client.clientName,
      ...client,
    }));
  }

  const fetchChecklistData = async () => {
    axiosService
      .get(
        process.env.REACT_APP_USER_BASE_URL +
          `/admin/checklist/client/${selectedClient?.clientId}`
      )
      .then((response) => {
        const data = response?.data;
        setCheckListData(data);
      })
      .catch((err) => {
        showErrorMessage(err);
      });
  };

  const fetchSavedAddresses = async () => {
    axiosService
      .get(
        process.env.REACT_APP_USER_BASE_URL +
          `/admin/client/${selectedClient?.clientId}/addresses`
      )
      .then((response) => {
        const data = response.data;
        const addresses = data?.addresses;
        setSavedAddresses(addresses);
      })
      .catch((err) => {
        showErrorMessage(err);
      });
  };
  const showError = (message) => {
    toast.error(message);
    return false;
  };
  const handleFinalProceed = () => {
    const { pickupDetails, dropDetails } = formDetails;

    if (!address.pickup) {
      return showError("Please enter a pickup address.");
    }

    if (!address.drop) {
      return showError("Please enter a drop address.");
    }

    if (address?.pickup?.description == address?.drop?.description) {
      return showError("Pickup and Drop address can't be the same.");
    }

    const validateDetails = (details, type, additionalFields = []) => {
      const requiredFields = [...commonFields, ...additionalFields];

      for (const field of requiredFields) {
        const value = String(details[field] || "").trim();

        if (!value) {
          return showError(
            `Please enter ${field
              .replace(/([A-Z])/g, " $1")
              .toLowerCase()} for ${type}.`
          );
        }
      }

      return true;
    };
    const commonFields = [
      "customerName",
      "customerPhoneNumber",
      "clientName",
      "clientNumber",
    ];

    const pickupFields = ["timeSlot", "checklistId"];
    const isPickupValid = validateDetails(
      pickupDetails,
      "pickup",
      pickupFields
    );
    const isDropValid = validateDetails(dropDetails, "drop");

    if (!isPickupValid || !isDropValid) return;

    if (
      !formDetails.pickupDetails.txnTimeMinutes ||
      !numberRegex.test(formDetails.pickupDetails.txnTimeMinutes)
    ) {
      return showError("Please add pickup transaction time in minutes");
    }
    if (
      !formDetails.dropDetails.txnTimeMinutes ||
      !numberRegex.test(formDetails.dropDetails.txnTimeMinutes)
    ) {
      return showError("Please add drop transaction time in minutes");
    }

    if (formDetails?.pickupDetails?.qcUrl) {
      if (!urlRegex.test(formDetails.pickupDetails.qcUrl)) {
        return showError("Please provide a valid URL for the pickup QC.");
      }
    }
    if (formDetails?.dropDetails?.qcUrl) {
      if (!urlRegex.test(formDetails.dropDetails.qcUrl)) {
        return showError("Please provide a valid URL for the drop QC.");
      }
    }

    //
    const getCurrentTimePlusTwoHours = () => {
      const now = new Date();
      now.setHours(now.getHours() + BUFFER_TIME);
      return now;
    };

    // Get the current time plus two hours
    const currentTimePlusTwoHours = getCurrentTimePlusTwoHours();
    const selectedTimeSlot = pickupDetails?.timeSlot;

    if (selectedTimeSlot) {
      const selectedTimeSlotValue = TIME_SLOTS.find(
        (slot) => slot.value === selectedTimeSlot
      )?.value;

      if (selectedTimeSlotValue) {
        const timeslotDate = new Date(pickupDetails?.date || "");
        const [slotHours, slotMinutes] = selectedTimeSlotValue.split(":");
        timeslotDate.setHours(parseInt(slotHours), parseInt(slotMinutes), 0, 0);

        if (timeslotDate < currentTimePlusTwoHours) {
          return showError(
            "Selected time slot must be at least 2 hours in the future."
          );
        }
      }
    }

    // Validate phone numbers
    const pickupPhoneError = phoneValidator(pickupDetails?.customerPhoneNumber);
    const dropPhoneError = phoneValidator(dropDetails?.customerPhoneNumber);

    if (pickupPhoneError || dropPhoneError) {
      return showError(
        pickupPhoneError ||
          dropPhoneError ||
          "Please enter a valid customer phone number."
      );
    }

    // Proceed to the next step
    setCurrentStep(3);
  };

  const handleAddTask = async () => {
    if (!formDetails.otherDetails.carType) {
      return showError("Please select car type");
    }

    if (!formDetails.otherDetails.transmissionType) {
      return showError("Please select transmission type");
    }

    if (!formDetails.otherDetails.fuelType) {
      return showError("Please select fuel type");
    }

    const createLocationDetails = (location) => {
      if (!location) return;

      const { main_text, secondary_text } = location.structured_formatting;
      const [heading, ...subHeading] = location.description.split(",");
      return {
        placeId: location.place_id || "",
        locality: location.locality || "",
        heading: main_text || heading?.trim() || "",
        subHeading: secondary_text || subHeading?.join(",")?.trim() || "",
        formattedAddress: location.description || "",
        pincode: location.pincode || "",
        city: location.city || "",
        state: location.state || "",
        lat: location.coordinates.lat || "",
        lng: location.coordinates.lng || "",
        label: "",
        regionId: selectedClient.clientId,
      };
    };

    const payload = {
      action: "CREATE_TRIP",
      actionDetails: {
        actionBy: "",
        uuid: uuidv4(),
        clientId: selectedClient?.clientId,
        clientName: selectedClient?.clientName,
        journeyMode: "ONE_WAY",
        tripBookingMode: "SCHEDULED",
        pickupDetails: {
          locationDetails: {
            type: "CUSTOM",
            addToSavedAddresses: false,
            details: createLocationDetails(address.pickup),
          },
          customerDetails: {
            name: formDetails.pickupDetails.customerName,
            phoneNumber: formDetails.pickupDetails.customerPhoneNumber,
          },
          pocDetails: {
            name: formDetails.pickupDetails.clientName,
            phoneNumber: formDetails.pickupDetails.clientNumber,
          },
          checklistId: formDetails.pickupDetails.checklistId.checklistId,
          slotStartTime: `${formDetails.pickupDetails.date} ${formDetails.pickupDetails.timeSlot}`,
          txnTimeMinutes: formDetails.pickupDetails.txnTimeMinutes,
          checklistParams: {
            qcUrl: formDetails?.pickupDetails?.qcUrl,
          },
        },
        dropDetails: {
          locationDetails: {
            type: "CUSTOM",
            addToSavedAddresses: false,
            details: createLocationDetails(address.drop),
          },
          customerDetails: {
            name: formDetails.dropDetails.customerName,
            phoneNumber: formDetails.dropDetails.customerPhoneNumber,
          },
          pocDetails: {
            name: formDetails.dropDetails.clientName,
            phoneNumber: formDetails.dropDetails.clientNumber,
          },
          checklistId: formDetails.dropDetails.checklistId.checklistId,
          txnTimeMinutes: formDetails.dropDetails.txnTimeMinutes,
          checklistParams: {
            qcUrl: formDetails?.dropDetails?.qcUrl,
          },
          // slotStartTime: `${formDetails.dropDetails.date} ${formDetails.dropDetails.timeSlot}`,
        },
        metadata: {},
        assetDetails: {
          type: "CAR",
          details: {
            make: "",
            model: "",
            carType: formDetails.otherDetails.carType.toUpperCase(),
            regoNo: formDetails.otherDetails.registrationNumber,
            transmissionType:
              formDetails.otherDetails.transmissionType.toUpperCase(),
            fuelType: formDetails.otherDetails.fuelType.toUpperCase(),
            year: "",
          },
        },
      },
    };
    setIsSubmitting(true);
    axiosService
      .post(process.env.REACT_APP_TRIP_BASE_URL + `/admin/trip`, payload)
      .then((res) => {
        toast.success("New task created successfully");
        setIsSubmitting(false);
        navigate(`/home/trip/${res.data.tripId}/details`);
      })
      .catch((err) => {
        setIsSubmitting(false);
        toast.error("Error Creating Trip");
        handleModalClose();
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (selectedClient?.clientId) {
      fetchChecklistData();
      fetchSavedAddresses();
    }
  }, [selectedClient?.clientId]);

  const formattedSavedAddresses = savedAddresses?.map(
    (item) => item?.formattedAddress
  );

  const checkListIds = checkListData && checkListData.map((item) => item);
  // const renderStepContent = () => ;

  return (
    <ModalLayout title={"Add Task"} onClose={handleModalClose}>
      <div className="tw-min-w-96">
        {currentStep === 1 && (
          <ClientSelection
            clients={clients}
            selectedClient={selectedClient}
            handleClientChange={(e) => setSelectedClient(e)}
            onProceed={() => setCurrentStep(2)}
          />
        )}
        {currentStep === 2 && (
          <PickDropForm
            address={address}
            setAddressCallback={setAddressCallback}
            formattedSavedAddresses={formattedSavedAddresses}
            formDetails={formDetails}
            checkListIds={checkListIds}
            // handleGoBack={handleGoBack}
            handleFinalProceed={handleFinalProceed}
            setFormDetails={setFormDetails}
          />
        )}
        {currentStep === 3 && (
          <CarDetailsForm
            handleAddTask={handleAddTask}
            formDetails={formDetails}
            // handleGoBack={handleGoBack}
            isSubmitting={isSubmitting}
            setFormDetails={setFormDetails}
          />
        )}
        {/* default: return (
        <ClientSelection
          clients={clients}
          selectedClient={selectedClient}
          handleClientChange={(e) => setSelectedClient(e)}
          onProceed={() => setCurrentStep(2)}
        />
        ); } } */}
      </div>
    </ModalLayout>
  );
};

export default AddTaskModal;

const ClientSelection = ({
  clients,
  selectedClient,
  handleClientChange,
  onProceed,
}) => {
  return (
    <>
      <label>Select Client</label>
      {clients.length > 0 ? (
        <DropdownSearchSingleSelect
          options={clients}
          textField="label"
          value={selectedClient || ""}
          onChange={handleClientChange}
          backupMessage="No Options Available"
          parentClass="tw-relative tw-w-full"
        />
      ) : (
        <input
          type="text"
          disabled
          placeholder="Loading..."
          className="tw-rounded-md tw-cursor-wait tw-outline-none tw-border tw-border-primaryBorder tw-px-2 tw-py-1 tw-w-full tw-animate-pulse"
        />
      )}
      <Button
        onClick={onProceed}
        disabled={!selectedClient}
        themeColor="primary"
        className="tw-mt-5 tw-w-full"
      >
        Next
      </Button>
    </>
  );
};

const PickDropForm = ({
  address,
  setAddressCallback,
  formDetails,
  checkListIds,
  handleFinalProceed,
  setFormDetails,
}) => {
  return (
    <div className="tw-w-[95vw]">
      <div className="tw-flex tw-mb-2 tw-gap-5">
        <div className="tw-w-1/4 tw-h-[80dvh] tw-relative tw-pr-5 tw-overflow-y-auto">
          <h5 className="tw-text-base tw-font-semibold">
            Enter your Pickup details
          </h5>
          <div className="">
            <div>
              {/* <label className="tw-text-sm tw-block tw-mb-3 tw-leading-none tw-text-gray-700 tw-font-medium">
                {!address?.pickup?.description && "Search"} Pickup Address
              </label> */}
              {address?.pickup?.description ? (
                <div className="tw-flex tw-items-center tw-justify-between custom-border-primary tw-rounded-md tw-p-3 tw-gap-2 tw-text-sm">
                  <span>{address?.pickup?.description}</span>
                  <button
                    onClick={() =>
                      setAddressCallback({
                        ...address,
                        pickup: "",
                      })
                    }
                    className="tw-bg-transparent tw-border-none"
                  >
                    <img src={closeBtn} alt="Close" className="tw-w-6" />
                  </button>
                </div>
              ) : (
                <DestinationAutoComplete
                  address={address}
                  pickup
                  setAddress={setAddressCallback}
                />
              )}
            </div>
          </div>
          <div className="tw-mt-4">
            <AddTaskForm
              formDetails={formDetails}
              setFormDetails={setFormDetails}
              isPickupForm={true}
              checkListData={checkListIds}
            />
          </div>
        </div>
        <div className="tw-w-1/4 tw-h-[80dvh] tw-relative tw-pr-5 tw-overflow-y-auto">
          <h5 className="tw-text-base tw-font-semibold">
            Enter your drop details
          </h5>
          <div className="">
            <div>
              {/* <label className="tw-text-sm tw-block tw-mb-3 tw-leading-none tw-text-gray-700 tw-font-medium">
                {!address?.drop?.description && "Search"} Drop Address
              </label> */}
              {address?.drop?.description ? (
                <div className="tw-flex tw-items-center tw-justify-between custom-border-primary tw-rounded-md tw-p-3 tw-gap-2 tw-text-sm">
                  <span>{address?.drop?.description}</span>
                  <button
                    onClick={() =>
                      setAddressCallback({
                        ...address,
                        drop: "",
                      })
                    }
                    className="tw-bg-transparent tw-border-none"
                  >
                    <img src={closeBtn} alt="Close" className="tw-w-6" />
                  </button>
                </div>
              ) : (
                <DestinationAutoComplete
                  address={address}
                  setAddress={setAddressCallback}
                />
              )}
            </div>
          </div>
          <div className="tw-mt-4">
            <AddTaskForm
              formDetails={formDetails}
              setFormDetails={setFormDetails}
              checkListData={checkListIds}
            />
          </div>
        </div>
        <div className="tw-w-2/4 tw-h-[80dvh] tw-relative custom-border-primary tw-overflow-hidden tw-rounded-md">
          <AddTaskMap
            initialPickup={address.pickup}
            initialDrop={address.drop}
            setAddress={setAddressCallback}
          />
        </div>
      </div>

      <Button
        onClick={handleFinalProceed}
        themeColor="primary"
        className="tw-inline-block tw-mt-4 tw-w-[100px]"
      >
        Next
      </Button>
    </div>
  );
};
