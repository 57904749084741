import { useState } from "react";
import { convertTo12HourFormat } from "../../../utils/helpers";
import DriverUniformAdherenceImage from "./DriverUniformAdherenceImage";
import { Link } from "react-router-dom";

const STATUS = {
  IDLE: "IDLE",
  TRIP: "TRIP",
  END: "END",
};

export default function DriverSessionAccordion({
  isOpen,
  sessionDetails,
  onClick,
  getSelectedTrip,
}) {
  const sessionTrips = sessionDetails?.trips;

  return (
    <>
      <div className="tw-flex tw-gap-5 tw-text-sm tw-items-center">
        <div
          onClick={onClick}
          className="custom-border-primary tw-min-w-[300px] tw-max-w-xs tw-w-full tw-p-4 tw-flex tw-items-center tw-justify-between tw-rounded-md tw-cursor-pointer"
        >
          <div className="tw-flex tw-items-center tw-gap-5">
            <div className="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-bg-green-500"></div>
            <div>
              <span className="tw-font-semibold tw-text-sm">Online</span>
              <div className="tw-flex tw-gap-2 tw-text-xs">
                <span>
                  {convertTo12HourFormat(sessionDetails.sessionStartTime)}
                </span>
                <span>
                  Uniform adherence{" "}
                  {sessionDetails.wearingUniform ? "passed" : "failed"}
                </span>
              </div>
            </div>
          </div>
          {!isOpen ? (
            <ChevronDownIcon fill={"#101010"} />
          ) : (
            <ChevronUpIcon fill={"#101010"} />
          )}
        </div>
        {sessionDetails.imageUrl && (
          <DriverUniformAdherenceImage
            status={sessionDetails.wearingUniform ? true : false}
            basePath={sessionDetails.imageUrl}
          />
        )}
      </div>
      {isOpen && (
        <div className="tw-relative tw-flex tw-flex-col tw-items-start tw-pl-8">
          <div
            style={{
              position: "absolute",
              left: 0,
              height: "calc(100% - 48px)",
              border: "1px solid #E4E5E9",
              marginLeft: "20px",
            }}
          />
          <ActivityCard
            title={"Idle"}
            // TODO: time for idle == prev states time..... incase of "Online" state time = sessionStartTime.... in case of trips time == tripEndTime .... don't show idle if actualTime is not available
            subtitle={convertTo12HourFormat(sessionDetails.sessionStartTime)}
            status={STATUS.IDLE}
            isTripDetailsCard={false}
            tripDetails={null}
          />

          {sessionTrips &&
            sessionTrips.length > 0 &&
            sessionTrips.map((item, index) => {
              return (
                <ActivityCard
                  getSelectedTrip={getSelectedTrip}
                  key={index}
                  title={`Trip ${index + 1}`}
                  subtitle={convertTo12HourFormat(
                    item.actualTripStartTime || item.expectedTripStartTime
                  )}
                  status={STATUS.TRIP}
                  isTripDetailsCard={true}
                  tripDetails={item}
                />
              );
            })}

          <ActivityCard
            title={sessionDetails.sessionEndTime ? "Offline" : "Not Offline"}
            subtitle={convertTo12HourFormat(sessionDetails.sessionEndTime)}
            status={STATUS.END}
            isTripDetailsCard={false}
            tripDetails={null}
          />
        </div>
      )}
    </>
  );
}

export function ActivityCard({
  title,
  subtitle,
  status,
  isTripDetailsCard,
  tripDetails,
  getSelectedTrip,
  isUpcoming = false,
}) {
  const [isShowDetailsOpen, setIsShowDetailsOpen] = useState(false);

  let statusClasss = "tw-bg-blue-600";

  if (status === STATUS.IDLE) {
    statusClasss = "tw-bg-purple-600";
  } else if (status === STATUS.END) {
    statusClasss = "tw-bg-red-600";
  }
  const handleShowDetails = () => {
    setIsShowDetailsOpen(!isShowDetailsOpen);
    if (!isShowDetailsOpen) {
      getSelectedTrip(tripDetails);
    } else {
      getSelectedTrip(null);
    }
  };
  const tripId = tripDetails?.tripId;
  return (
    <>
      <div
        style={{ paddingLeft: "20px", paddingTop: "20px" }}
        className="tw-relative tw-flex tw-items-center"
      >
        <div
          style={{ border: "1px solid #E4E5E9" }}
          className="tw-absolute tw-left-[-10px] tw-top-[44.5%] tw-w-8"
        />
        <div
          className={`tw-p-4 tw-pr-8 ${
            isTripDetailsCard ? "tw-h-[120px]" : "tw-h-[70px]"
          } tw-bg-white tw-border tw-rounded-md tw-shadow-sm custom-border-primary`}
        >
          <div className="tw-flex tw-gap-4 tw-items-center">
            <div>
              <div
                className={`tw-h-[10px] tw-w-[10px] ${statusClasss} tw-rounded-full`}
              />
            </div>
            <div>
              <span className="tw-font-semibold tw-block tw-text-sm">
                {title}
              </span>
              <span className="tw-text-gray-500 tw-block tw-text-xs">
                {subtitle}
              </span>
              {isTripDetailsCard && tripId && (
                <Link
                  to={`/home/trip/${tripId}/details`}
                  className="tw-text-gray-500 hover:tw-text-gray-500 tw-text-sm tw-block tw-mt-1"
                >
                  {tripId}
                </Link>
              )}
              {isTripDetailsCard && (
                <span
                  onClick={handleShowDetails}
                  className="tw-block tw-text-blue-600 tw-mt-1 tw-text-[12px] tw-cursor-pointer"
                >
                  {isShowDetailsOpen ? "Hide Details" : "Show Details"}
                  <span className="tw-ml-1">
                    {!isShowDetailsOpen ? (
                      <ChevronUpIcon fill={"#2563eb"} />
                    ) : (
                      <ChevronDownIcon fill={"#2563eb"} />
                    )}
                  </span>
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* ADDITIONAL TRIP DETAILS */}
      {isShowDetailsOpen && (
        <div
          className={`tw-relative tw-text-sm tw-flex tw-flex-col tw-items-start tw-pl-8 tw-transition-all tw-duration-300`}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              height: "72px",
              border: "1px solid #E4E5E9",
              marginLeft: "40px",
            }}
          />
          <AdditionalTripDetailsCard
            isUpcoming
            additionalDetails={tripDetails}
          />
        </div>
      )}
    </>
  );
}

function AdditionalTripDetailsCard({ additionalDetails, isUpcoming }) {
  const tripStartTime =
    additionalDetails?.actualTripStartTime ||
    additionalDetails?.expectedTripStartTime;
  const tripEndTime =
    additionalDetails?.actualTripEndTime ||
    additionalDetails?.expectedTripEndTime;

  return (
    <>
      <div
        style={{ paddingLeft: "40px", paddingTop: "20px" }}
        className="tw-relative tw-text-sm tw-flex tw-items-center tw-mb-4"
      >
        {!isUpcoming && (
          <>
            <div
              style={{ border: "1px solid #E4E5E9", marginLeft: "10px" }}
              className="tw-absolute tw-left-[0px] tw-top-[50%] tw-w-8 tw-bg-blue-500"
            />
            <div
              className={`tw-p-4 tw-pr-8 ${"tw-h-[120px]"} tw-bg-white tw-border tw-rounded-md tw-shadow-sm custom-border-primary`}
            >
              <div>
                <span className="tw-block tw-font-semibold">
                  Driver started at
                </span>
                <span className="tw-block tw-text-gray-500">
                  {convertTo12HourFormat(
                    additionalDetails?.driverStartedAtTime
                  ) || "-"}
                </span>
              </div>
              <div className="tw-mt-2">
                <span className="tw-block tw-font-semibold">
                  Driver reached at
                </span>
                <span className="tw-block tw-text-gray-500">
                  {convertTo12HourFormat(
                    additionalDetails?.driverReachedAtTime
                  ) || "-"}
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      <div
        style={{ paddingLeft: "40px", paddingTop: "0px" }}
        className="tw-relative tw-flex tw-text-sm tw-items-center tw-mb-4"
      >
        <div
          style={{ border: "1px solid #E4E5E9", marginLeft: "10px" }}
          className="tw-absolute tw-left-[45%] tw-top-[-18px] tw-h-[18px] tw-bg-blue-500"
        />
        <div
          className={`tw-p-4 tw-pr-8 ${"tw-h-[120px]"} tw-bg-white tw-border tw-rounded-md tw-shadow-sm custom-border-primary`}
        >
          <div>
            <span className="tw-block tw-font-semibold">Trip started at</span>
            <span className="tw-block tw-text-gray-500">
              {convertTo12HourFormat(tripStartTime)}
            </span>
          </div>
          <div className="tw-mt-2">
            <span className="tw-block tw-font-semibold">Trip ended at</span>
            <span className="tw-block tw-text-gray-500">
              {convertTo12HourFormat(tripEndTime)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export function ChevronDownIcon({ fill }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6465 5.64586C12.693 5.5994 12.7481 5.56255 12.8088 5.53741C12.8695 5.51227 12.9346 5.49933 13.0003 5.49933C13.066 5.49933 13.131 5.51227 13.1917 5.53741C13.2524 5.56255 13.3076 5.5994 13.354 5.64586C13.4005 5.69231 13.4373 5.74746 13.4625 5.80816C13.4876 5.86885 13.5006 5.93391 13.5006 5.99961C13.5006 6.0653 13.4876 6.13036 13.4625 6.19105C13.4373 6.25175 13.4005 6.3069 13.354 6.35336L8.35403 11.3534C8.30759 11.3998 8.25245 11.4367 8.19175 11.4619C8.13105 11.487 8.06599 11.5 8.00028 11.5C7.93457 11.5 7.86951 11.487 7.80881 11.4619C7.74811 11.4367 7.69296 11.3998 7.64653 11.3534L2.64653 6.35336C2.55271 6.25954 2.5 6.13229 2.5 5.99961C2.5 5.86692 2.55271 5.73968 2.64653 5.64586C2.74035 5.55204 2.8676 5.49933 3.00028 5.49933C3.13296 5.49933 3.26021 5.55204 3.35403 5.64586L8.00028 10.2927L12.6465 5.64586Z"
        fill={fill}
      />
    </svg>
  );
}

export function ChevronUpIcon({ fill }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.35347 10.3541C3.30702 10.4006 3.25187 10.4374 3.19117 10.4626C3.13047 10.4877 3.06542 10.5007 2.99972 10.5007C2.93402 10.5007 2.86897 10.4877 2.80827 10.4626C2.74758 10.4374 2.69243 10.4006 2.64597 10.3541C2.59952 10.3077 2.56267 10.2525 2.53752 10.1918C2.51238 10.1311 2.49944 10.0661 2.49944 10.0004C2.49944 9.9347 2.51238 9.86964 2.53752 9.80894C2.56267 9.74825 2.59952 9.6931 2.64597 9.64664L7.64597 4.64664C7.69241 4.60015 7.74755 4.56328 7.80825 4.53811C7.86895 4.51295 7.93401 4.5 7.99972 4.5C8.06543 4.5 8.13049 4.51295 8.19119 4.53811C8.25189 4.56328 8.30704 4.60015 8.35347 4.64664L13.3535 9.64664C13.4473 9.74046 13.5 9.86771 13.5 10.0004C13.5 10.1331 13.4473 10.2603 13.3535 10.3541C13.2597 10.448 13.1324 10.5007 12.9997 10.5007C12.867 10.5007 12.7398 10.448 12.646 10.3541L7.99972 5.70727L3.35347 10.3541Z"
        fill={fill}
      />
    </svg>
  );
}
