import * as React from "react";
import { useLocation, useNavigate, Outlet, Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../slices/authSlice";
import { clearLocalStorage, showErrorMessage } from "../utils/helpers";
import { useDescope } from "@descope/react-sdk";
import logo from "../assets/logo.svg";
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from "./driver-management/driver-online-activity/DriverSessionAccordion";
// import PathfinderSvg from "../assets/Pathfinder.svg";
export const items = [
  {
    text: "Trip Management",
    selected: false,
    route: [
      "/home/trip",
      "/home/trip/tripId/details",
      "/home/trip/tripId/assign-driver",
    ],
    icon: "",
  },
  // {
  //   text: "Fleet Management",
  //   selected: false,
  //   route: [
  //     "/home/driver",
  //     "/home/driver/driverId/profile",
  //     "/home/driver/driverId/account",
  //     "/home/driver/driverId/online-activity",
  //   ],
  //   icon: "",
  // },
  {
    text: "Driver Management",
    selected: false,
    route: [
      "/home/driver",
      "/home/driver/driverId/profile",
      "/home/driver/driverId/account",
      "/home/driver/driverId/online-activity",
    ],
    icon: "",
    children: [
      {
        text: "All Drivers",
        route: "/home/driver/all",
      },
      {
        text: "Fleet View",
        route: "/home/driver/",
      },
      {
        text: "Driver Payout",
        route: "/home/driver/payout",
      },
    ],
  },
  {
    text: "Pricing Logic Management",
    selected: false,
    route: ["/home/pricing"],
    icon: "",
  },
  {
    text: "Customer Management",
    selected: false,
    route: ["/home/customer-management"],
    icon: "",
  },
  // {
  //   text: "Driver Payout",
  //   selected: false,
  //   route: ["/home/driver/payout"],
  //   icon: "",
  // },
  {
    text: "Bulk Operations",
    selected: false,
    route: ["/home/operations"],
    icon: "",
  },
  {
    text: "Coupon Management",
    selected: false,
    route: ["/home/coupon"],
    icon: "",
    children: [
      {
        text: "Grievance Coupon",
        route: "/home/coupon/grievance",
      },
      {
        text: "Referral Coupon",
        route: "/home/coupon/referral",
      },
    ],
  },
  // {
  //   text: "Grievance Coupon Management",
  //   selected: false,
  //   route: ["/home/coupon-grievance"],
  //   icon: "",
  // },
  // {
  //   text: "Referral Coupon Management",
  //   selected: false,
  //   route: ["/home/coupon-referral"],
  //   icon: "",
  // },
  {
    text: "Subscriptions",
    selected: false,
    route: ["/home/subscriptions", "/home/subscriptions/subId/details"],
    icon: "",
  },
];

export const DrawerContainer = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.auth?.userDetails);
  const { logout } = useDescope();

  const onSignOut = () => {
    logout()
      .then(() => {
        clearLocalStorage();
        dispatch(logOut());
        navigate("/login");
      })
      .catch((error) => {
        showErrorMessage(error);
      });
  };
  return (
    <>
      <main className="tw-flex tw-items-start tw-h-[100dvh] tw-overflow-hidden">
        <Sidebar items={items} user={user} onSignOut={onSignOut} />
        {props.children}
        <Outlet />
      </main>
    </>
  );
};

// New Sidebar
const Sidebar = ({ items, user, onSignOut }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [expandedItem, setExpandedItem] = React.useState(null);

  const handleSelect = (item) => {
    if (item.children) {
      setExpandedItem(expandedItem === item.text ? null : item.text);
    } else {
      navigate(item.route[0]);
    }
  };

  const handleChildSelect = (child) => {
    navigate(child.route);
  };

  const isItemSelected = (item) => {
    return location.pathname.startsWith(item.route[0]);
  };

  const isChildSelected = (child) => {
    return location.pathname === child.route;
  };

  return (
    <>
      <aside
        style={{ borderRight: "2px solid #E8E8E8" }}
        className="tw-w-[250px] tw-flex tw-flex-col tw-justify-between tw-bg-[#FAFAFA] tw-h-full tw-relative"
      >
        <div>
          <div
            className="tw-p-5 tw-h-20 tw-flex tw-items-center"
            style={{ borderBottom: "2px solid #E8E8E8" }}
          >
            <Link className="tw-w-1/2" to={"/home/trip"}>
              <img src={logo} alt="Pathfinder" className="tw-w-full" />
            </Link>
          </div>
          <div className="tw-overflow-hidden tw-select-none tw-text-ellipsis">
            {items.map((item, index) => (
              <div key={index}>
                <div
                  className={`${
                    item.children &&
                    "tw-flex tw-items-center tw-justify-between"
                  }  tw-overflow-hidden tw-text-ellipsis tw-px-5 tw-cursor-pointer tw-py-3 ${
                    isItemSelected(item)
                      ? "tw-bg-primary tw-text-white"
                      : "hover:tw-bg-black/5"
                  }`}
                  onClick={() => handleSelect(item)}
                >
                  <span title={item.text} className="tw-whitespace-nowrap">
                    {item.text}
                  </span>
                  {item.children && (
                    <>
                      {expandedItem !== item.text ? (
                        <ChevronDownIcon
                          fill={isItemSelected(item) ? "white" : "black"}
                        />
                      ) : (
                        <ChevronUpIcon
                          fill={isItemSelected(item) ? "white" : "black"}
                        />
                      )}
                    </>
                  )}
                </div>
                {expandedItem === item.text &&
                  item.children &&
                  item.children.map((child, childIndex) => (
                    <div
                      key={childIndex}
                      className={`tw-px-8 tw-py-2 tw-cursor-pointer tw-text-sm ${
                        isChildSelected(child)
                          ? "tw-bg-primary/20 tw-text-primary"
                          : "hover:tw-bg-black/5"
                      }`}
                      onClick={() => handleChildSelect(child)}
                    >
                      <span title={child.text}>{child.text}</span>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>

        <div
          className="tw-flex tw-p-5 tw-items-center tw-gap-2"
          style={{ borderTop: "2px solid #E8E8E8" }}
        >
          <img
            src={require("../assets/people/user-avatar.jpg")}
            alt="user avatar"
            className="tw-w-14"
          />
          <div className="tw-w-full tw-overflow-hidden">
            <span
              title={user?.email}
              className="tw-text-sm tw-cursor-default tw-text-ellipsis tw-overflow-hidden tw-block"
            >
              {user?.email}
            </span>
            <Button
              size={"small"}
              className="tw-bg-primary/80 tw-text-white tw-mt-2 tw-border-none hover:tw-bg-primary"
              onClick={onSignOut}
            >
              Log Out
            </Button>
          </div>
        </div>
      </aside>
    </>
  );
};
