import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CategoryFilterCell,
  CategoryFilterMultiSelectCell,
  getLabelFromEnum,
  stringToSentenceCase,
  timeConverter,
} from "../../utils/helpers";
import GridLoader from "../common/GridLoader";
import { APIConfig } from "../../utils/constants/api.constants";
import { TripConfig } from "../../config/tripConfig";
import { useSelector } from "react-redux";
import TopFilters from "../common/TopFilters";
import { Button } from "@progress/kendo-react-buttons";
import AddTaskModal from "./AddTaskModal";
import axiosService from "../../init/axios";

const TripManagementTable = ({
  setTripDetailsSelected,
  dataState,
  setDataState,
  setStatusFilter,
  statusFilter,
  calendarDate,
  calendarToggle,
}) => {
  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL + APIConfig.tripManagement.getAllTrips;
  const navigate = useNavigate();
  const selectedTripRegionFilters = useSelector((state) => state.region?.trip);
  const [trips, setTrips] = useState({
    data: [],
    total: 0,
  });
  const [filterArr, setFilterArr] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  const columns = [
    {
      id: "tripId",
      field: "tripId",
      title: "Trip ID",
      filterable: true,
      filterKey: "trip_id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 150,
    },
    {
      id: "customerName",
      field: "customerName",
      title: "Customer Name",
      filterable: true,
      filterKey: "customer_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 180,
    },
    {
      id: "customerMobile",
      field: "customerMobile",
      title: "Customer Mobile",
      filterable: true,
      filterKey: "customer_mobile",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 150,
    },
    // {
    //   id: "customerCompany",
    //   field: "customerCompany",
    //   title: "Customer Company",
    //   filterable: true,
    //   filterKey: "customer_company",
    //   filterType: "string",
    //   defaultFilterOperator: "lk",
    //   width: 180,
    // },
    {
      id: "clientType",
      field: "clientType",
      title: "Client Type",
      filterable: true,
      filterKey: "client_type",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: TripConfig.clientTypes,
      width: 150,
    },
    {
      id: "clientName",
      field: "clientName",
      title: "Client Name",
      filterable: true,
      filterKey: "client_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 180,
    },
    {
      id: "derivedTripType",
      field: "derivedTripType",
      title: "Trip Type",
      filterable: true,
      filterKey: "derived_trip_type",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: TripConfig.derivedTripType,
      width: 130,
    },
    {
      id: "journeyMode",
      field: "journeyMode",
      title: "Journey Mode",
      filterable: true,
      filterKey: "journey_mode",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: TripConfig.journeyMode,
      width: 130,
    },
    {
      id: "tripBookingMode",
      field: "tripBookingMode",
      title: "Trip Booking Mode",
      filterable: true,
      filterKey: "trip_booking_mode",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: TripConfig.tripBookingMode,
      width: 150,
    },
    {
      id: "stage",
      field: "stage",
      title: "Stage",
      filterable: true,
      filterKey: "stage",
      filterType: "string",
      defaultFilterOperator: "eqIn",
      data: TripConfig.stage,
      width: 220,
    },
    {
      id: "tag",
      field: "tag",
      title: "Tag",
      filterable: true,
      filterKey: "tag",
      filterType: "string",
      defaultFilterOperator: "eq",
      data: TripConfig.tag,
      width: 130,
    },
    {
      id: "driverId",
      field: "driverId",
      title: "Driver Id",
      filterable: true,
      filterKey: "driver_id",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 100,
    },
    {
      id: "fullName",
      field: "fullName",
      title: "Driver Name",
      filterable: false,
      filterKey: "full_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 180,
    },
    {
      id: "driverMobile",
      field: "driverMobile",
      title: "Driver Mobile",
      filterable: false,
      filterKey: "driver_mobile",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 180,
    },
    {
      id: "regionId",
      field: "regionId",
      title: "Region Id",
      filterable: false,
      width: 180,
    },
    {
      id: "tripCreationTime",
      field: "tripCreationTime",
      title: "Trip Creation Time",
      filterable: false,
      width: 200,
      cell: (e) => <td>{timeConverter(e.dataItem.tripCreationTime)}</td>,
    },
    {
      id: "expectedTripStartTime",
      field: "expectedTripStartTime",
      title: "Expected Trip Start Time",
      filterable: false,
      width: 200,
      cell: (e) => <td>{timeConverter(e.dataItem.expectedTripStartTime)}</td>,
    },
    {
      id: "actualTripStartTime",
      field: "actualTripStartTime",
      title: "Actual Trip Start Time",
      filterable: false,
      width: 200,
      cell: (e) => <td>{timeConverter(e.dataItem.actualTripStartTime)}</td>,
    },
    {
      id: "expectedTripEndTime",
      field: "expectedTripEndTime",
      title: "Expected Trip End Time",
      filterable: false,
      width: 200,
      cell: (e) => <td>{timeConverter(e.dataItem.expectedTripEndTime)}</td>,
    },
    {
      id: "actualTripEndTime",
      field: "actualTripEndTime",
      title: "Actual Trip End Time",
      filterable: false,
      width: 200,
      cell: (e) => <td>{timeConverter(e.dataItem.actualTripEndTime)}</td>,
    },
    {
      id: "pickLocation",
      field: "pickLocation",
      title: "Pick Location",
      filterable: false,
      width: 700,
    },
    {
      id: "dropLocation",
      field: "dropLocation",
      title: "Drop Location",
      filterable: false,
      width: 700,
    },
  ];

  const getFilterData = async () => {
    let filterBaseUrl =
      process.env.REACT_APP_TRIP_BASE_URL +
      APIConfig.tripManagement.getTripStats;
    let url;
    if (selectedTripRegionFilters.length > 0) {
      let values = selectedTripRegionFilters.map((obj) => obj.value).join(",");
      url = filterBaseUrl + "?parent_region_id=" + values + ":in:string";
    } else {
      url = filterBaseUrl;
    }
    await axiosService
      .get(
        url +
          `${selectedTripRegionFilters.length > 0 ? "&" : "?"}${
            calendarToggle
              ? `expected_trip_start_time=${calendarDate}:eq:date&`
              : ""
          }scenario=AP_PANEL_HIDE_B2C_SUBS`
      )
      .then((data) => {
        setFilterArr(data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [selectedTripRegionFilters, calendarDate, calendarToggle]);

  const dataStateChange = (e) => {
    let otherParams = {
      ...(calendarToggle && {
        expected_trip_start_time: calendarDate + ":eq:date",
      }),
      scenario: "AP_PANEL_HIDE_B2C_SUBS",
    };
    if (selectedTripRegionFilters?.length > 0) {
      const regionQueryStr = selectedTripRegionFilters.reduce(
        (acc, curr) => (acc += curr?.value + ","),
        ""
      );
      otherParams["parent_region_id"] =
        regionQueryStr.slice(0, -1) + ":in:string";
      if (statusFilter?.filters?.length > 0) {
        const [key, value] = statusFilter.filters[0].split("=");
        otherParams[key] = value;
      }
      setDataState({
        ...e.dataState,
        otherParams: otherParams,
      });
    } else {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0
      ) {
        if (statusFilter?.filters?.length > 0) {
          const [key, value] = statusFilter.filters[0].split("=");
          otherParams[key] = value;
        }
        setDataState({
          ...e.dataState,
          otherParams: otherParams,
        });
      } else {
        setDataState(e.dataState);
      }
    }
  };
  const dataReceived = (dataObj) => {
    const arr = dataObj.data.trips.map((trip) => {
      //Getting labels from enum
      const updatedTripDetails = getLabelFromEnum(
        [
          "derivedTripType",
          "journeyMode",
          "scheduled",
          "stage",
          "tripBookingMode",
          "tag",
        ],
        trip,
        "trip"
      );
      let tripObj = {};

      if (trip?.customerName) {
        tripObj = {
          ...tripObj,
          customerName: stringToSentenceCase(trip?.customerName),
        };
      } else {
        tripObj = { ...tripObj, customerName: "" };
      }

      if (trip?.customerMobile) {
        tripObj = { ...tripObj, customerMobile: trip?.customerMobile };
      } else {
        tripObj = { ...tripObj, customerMobile: "" };
      }

      if (trip?.tripBookingMode) {
        tripObj = { ...tripObj, tripBookingMode: trip?.tripBookingMode };
      } else {
        tripObj = { ...tripObj, tripBookingMode: "" };
      }

      if (trip?.tripCreationTime) {
        tripObj = { ...tripObj, tripCreationTime: trip?.tripCreationTime };
      } else {
        tripObj = { ...tripObj, tripCreationTime: "" };
      }

      if (
        trip?.actualSourceLocation?.formattedAddress ||
        trip?.actualSourceLocation?.heading
      ) {
        tripObj = {
          ...tripObj,
          pickLocation:
            trip?.actualSourceLocation?.formattedAddress ||
            trip?.actualSourceLocation?.heading +
              (trip?.actualSourceLocation?.subHeading
                ? ", " + trip?.actualSourceLocation?.subHeading
                : ""),
        };
      } else if (
        trip?.expectedSourceLocation?.formattedAddress ||
        trip?.expectedSourceLocation?.heading
      ) {
        tripObj = {
          ...tripObj,
          pickLocation:
            trip?.expectedSourceLocation?.formattedAddress ||
            trip?.expectedSourceLocation?.heading +
              (trip?.expectedSourceLocation?.subHeading
                ? ", " + trip?.expectedSourceLocation?.subHeading
                : ""),
        };
      } else {
        tripObj = { ...tripObj, pickLocation: "" };
      }

      if (
        trip?.actualDestinationLocation?.formattedAddress ||
        trip?.actualDestinationLocation?.heading
      ) {
        tripObj = {
          ...tripObj,
          dropLocation:
            trip?.actualDestinationLocation?.formattedAddress ||
            trip?.actualDestinationLocation?.heading +
              (trip?.actualDestinationLocation?.subHeading
                ? ", " + trip?.actualDestinationLocation?.subHeading
                : ""),
        };
      } else if (
        trip?.expectedDestinationLocation?.formattedAddress ||
        trip?.expectedDestinationLocation?.heading
      ) {
        tripObj = {
          ...tripObj,
          dropLocation:
            trip?.expectedDestinationLocation?.formattedAddress ||
            trip?.expectedDestinationLocation?.heading +
              (trip?.expectedDestinationLocation?.subHeading
                ? ", " + trip?.expectedDestinationLocation?.subHeading
                : ""),
        };
      } else {
        tripObj = { ...tripObj, dropLocation: "" };
      }

      if (trip?.driverDetails?.fullName) {
        tripObj = {
          ...tripObj,
          fullName: stringToSentenceCase(trip?.driverDetails?.fullName),
        };
      } else {
        tripObj = { ...tripObj, fullName: "" };
      }

      if (trip?.driverMobile) {
        tripObj = {
          ...tripObj,
          driverMobile: stringToSentenceCase(trip?.driverMobile),
        };
      } else {
        tripObj = { ...tripObj, driverMobile: "" };
      }
      tripObj = {
        ...tripObj,
        // customerCompany: trip?.customerCompany ? trip.customerCompany : "",
        clientType: trip?.clientType ? trip.clientType : "",
        clientName: trip?.clientName ? trip.clientName : "",
      };
      return { ...updatedTripDetails, ...tripObj };
    });
    setTrips({ ...dataObj, data: arr });
  };

  const onCustomerRowClick = (item) => {
    setTripDetailsSelected(true);
    navigate("/home/trip/" + item?.dataItem?.tripId + "/details");
  };

  const handleFilterClick = (filter) => {
    setStatusFilter(filter);
    let otherParams = {
      ...(calendarToggle && {
        expected_trip_start_time: calendarDate + ":eq:date",
      }),
      scenario: "AP_PANEL_HIDE_B2C_SUBS",
    };
    if (selectedTripRegionFilters?.length > 0) {
      const regionQueryStr = selectedTripRegionFilters.reduce(
        (acc, curr) => (acc += curr?.value + ","),
        ""
      );
      otherParams["parent_region_id"] =
        regionQueryStr.slice(0, -1) + ":in:string";
    }
    if (filter.filters.length > 0) {
      const [key, value] = filter.filters[0].split("=");
      otherParams[key] = value;
    }

    setDataState((prevState) => ({
      ...prevState,
      otherParams,
    }));
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  return (
    <>
      <div className="tw-flex tw-justify-between tw-gap-10 tw-items-center tw-mb-5">
        <div className="tw-overflow-x-auto tw-w-full">
          {filterArr.trips ? (
            <TopFilters
              statusFilter={statusFilter}
              handleFilterClick={handleFilterClick}
              filters={filterArr.trips}
            />
          ) : (
            <div className="tw-flex tw-items-center tw-gap-0 tw-w-3/4 tw-h-10 tw-animate-pulse topFilter"></div>
          )}
        </div>
        <Button onClick={handleModalOpen} themeColor={"primary"}>
          Add Task
        </Button>
      </div>
      <div>
        <Grid
          filterable={true}
          sortable={false}
          pageable={true}
          {...dataState}
          data={trips}
          onDataStateChange={dataStateChange}
          onRowDoubleClick={onCustomerRowClick}
        >
          {columns?.map((column) => (
            <Column
              key={column?.id}
              field={column?.field}
              title={column?.title}
              filterable={column?.filterable}
              width={column?.width}
              cell={column?.cell}
              filterCell={
                (column.defaultFilterOperator === "eq" &&
                  ((props) => CategoryFilterCell(props, column))) ||
                (column.defaultFilterOperator === "eqIn" &&
                  ((props) => CategoryFilterMultiSelectCell(props, column)))
              }
            />
          ))}
        </Grid>
        <GridLoader
          baseUrl={baseUrl}
          columns={columns}
          dataState={dataState}
          onDataReceived={dataReceived}
        />
      </div>

      {modalOpen && (
        <>
          <AddTaskModal handleModalClose={handleModalClose} />
        </>
      )}
    </>
  );
};

export default TripManagementTable;
