import { FormInput } from "../../utils/forms/FormComponents";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const TIME_SLOTS = [
  { label: "12:00 AM - 1:00 AM", value: "00:00:00" },
  { label: "12:30 AM - 1:30 AM", value: "00:30:00" },
  { label: "1:00 AM - 2:00 AM", value: "01:00:00" },
  { label: "1:30 AM - 2:30 AM", value: "01:30:00" },
  { label: "2:00 AM - 3:00 AM", value: "02:00:00" },
  { label: "2:30 AM - 3:30 AM", value: "02:30:00" },
  { label: "3:00 AM - 4:00 AM", value: "03:00:00" },
  { label: "3:30 AM - 4:30 AM", value: "03:30:00" },
  { label: "4:00 AM - 5:00 AM", value: "04:00:00" },
  { label: "4:30 AM - 5:30 AM", value: "04:30:00" },
  { label: "5:00 AM - 6:00 AM", value: "05:00:00" },
  { label: "5:30 AM - 6:30 AM", value: "05:30:00" },
  { label: "6:00 AM - 7:00 AM", value: "06:00:00" },
  { label: "6:30 AM - 7:30 AM", value: "06:30:00" },
  { label: "7:00 AM - 8:00 AM", value: "07:00:00" },
  { label: "7:30 AM - 8:30 AM", value: "07:30:00" },
  { label: "8:00 AM - 9:00 AM", value: "08:00:00" },
  { label: "8:30 AM - 9:30 AM", value: "08:30:00" },
  { label: "9:00 AM - 10:00 AM", value: "09:00:00" },
  { label: "9:30 AM - 10:30 AM", value: "09:30:00" },
  { label: "10:00 AM - 11:00 AM", value: "10:00:00" },
  { label: "10:30 AM - 11:30 AM", value: "10:30:00" },
  { label: "11:00 AM - 12:00 PM", value: "11:00:00" },
  { label: "11:30 AM - 12:30 PM", value: "11:30:00" },
  { label: "12:00 PM - 1:00 PM", value: "12:00:00" },
  { label: "12:30 PM - 1:30 PM", value: "12:30:00" },
  { label: "1:00 PM - 2:00 PM", value: "13:00:00" },
  { label: "1:30 PM - 2:30 PM", value: "13:30:00" },
  { label: "2:00 PM - 3:00 PM", value: "14:00:00" },
  { label: "2:30 PM - 3:30 PM", value: "14:30:00" },
  { label: "3:00 PM - 4:00 PM", value: "15:00:00" },
  { label: "3:30 PM - 4:30 PM", value: "15:30:00" },
  { label: "4:00 PM - 5:00 PM", value: "16:00:00" },
  { label: "4:30 PM - 5:30 PM", value: "16:30:00" },
  { label: "5:00 PM - 6:00 PM", value: "17:00:00" },
  { label: "5:30 PM - 6:30 PM", value: "17:30:00" },
  { label: "6:00 PM - 7:00 PM", value: "18:00:00" },
  { label: "6:30 PM - 7:30 PM", value: "18:30:00" },
  { label: "7:00 PM - 8:00 PM", value: "19:00:00" },
  { label: "7:30 PM - 8:30 PM", value: "19:30:00" },
  { label: "8:00 PM - 9:00 PM", value: "20:00:00" },
  { label: "8:30 PM - 9:30 PM", value: "20:30:00" },
  { label: "9:00 PM - 10:00 PM", value: "21:00:00" },
  { label: "9:30 PM - 10:30 PM", value: "21:30:00" },
  { label: "10:00 PM - 11:00 PM", value: "22:00:00" },
  { label: "10:30 PM - 11:30 PM", value: "22:30:00" },
  { label: "11:00 PM - 12:00 AM", value: "23:00:00" },
  { label: "11:30 PM - 12:30 AM", value: "23:30:00" },
];

const CAR_TYPES = [
  {
    value: "Sedan",
    text: "Sedan",
  },
  {
    value: "Hatchback",
    text: "Hatchback",
  },
  {
    value: "SUV",
    text: "SUV",
  },
  {
    value: "Luxury",
    text: "Luxury",
  },
];

const TRANSMISSION_TYPES = [
  {
    value: "Manual",
    text: "Manual",
  },
  {
    value: "Automatic",
    text: "Automatic",
  },
];

const FUEL_TYPES = [
  {
    value: "Petrol",
    text: "Petrol",
  },
  {
    value: "Diesel",
    text: "Diesel",
  },
  {
    value: "CNG",
    text: "CNG",
  },
  {
    value: "Electric",
    text: "Electric",
  },
];

export default function AddTaskForm({
  formDetails,
  setFormDetails,
  isPickupForm,
  checkListData = [],
}) {
  const filterTimeSlots = () => {
    const selectedDate = isPickupForm
      ? formDetails?.pickupDetails?.date
      : formDetails?.dropDetails?.date;

    if (!selectedDate) return TIME_SLOTS;

    const selectedDateTime = new Date(`${selectedDate}T00:00:00`);
    const currentDateTime = new Date();
    const twoHoursLater = new Date(
      currentDateTime.getTime() + 2 * 60 * 60 * 1000
    );

    if (selectedDateTime.toDateString() === currentDateTime.toDateString()) {
      return TIME_SLOTS.filter((slot) => {
        const slotDateTime = new Date(`${selectedDate}T${slot.value}`);
        return slotDateTime >= twoHoursLater;
      });
    } else if (selectedDateTime > currentDateTime) {
      return TIME_SLOTS;
    }

    return [];
  };

  const handleSaveDetails = (key, value) => {
    let isValid = true;
    let validationMessage = "";

    if (key === "customerPhoneNumber" || key === "clientNumber") {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(value)) {
        isValid = false;
        validationMessage = "Please enter a valid 10-digit phone number.";
      }
    }

    // Update form details and validation state
    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [isPickupForm ? "pickupDetails" : "dropDetails"]: {
        ...prevDetails[isPickupForm ? "pickupDetails" : "dropDetails"],
        [key]: value,
        [`${key}Valid`]: isValid,
        [`${key}ValidationMessage`]: validationMessage,
      },
    }));
  };

  const selectedTimeSlot = isPickupForm
    ? TIME_SLOTS.find(
        (item) => item.value === formDetails?.pickupDetails?.timeSlot
      )
    : TIME_SLOTS.find(
        (item) => item.value === formDetails?.dropDetails?.timeSlot
      );

  return (
    <>
      <div className="tw-mt-5">
        <h5 className="tw-text-base tw-font-semibold">Customer details</h5>
        <div className="tw-grid tw-grid-cols-1 tw-gap-5 custom-border-primary tw-p-3 tw-rounded-md">
          <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              Customer Name
            </label>

            <FormInput
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.customerName
                  : formDetails?.dropDetails?.customerName
              }
              placeholder="Customer Name"
              name="customerName"
              maxLength={30}
              onChange={(e) =>
                handleSaveDetails("customerName", e.target.value)
              }
            />
          </div>

          <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              Enter primary number
            </label>

            <FormInput
              name="customerPhoneNumber"
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.customerPhoneNumber
                  : formDetails?.dropDetails?.customerPhoneNumber
              }
              valid={
                isPickupForm
                  ? formDetails?.pickupDetails?.customerPhoneNumberValid
                  : formDetails?.dropDetails?.customerPhoneNumberValid
              }
              validationMessage={
                isPickupForm
                  ? formDetails?.pickupDetails
                      ?.customerPhoneNumberValidationMessage
                  : formDetails?.dropDetails
                      ?.customerPhoneNumberValidationMessage
              }
              placeholder="99999 99999"
              maxLength={10}
              onChange={(e) =>
                handleSaveDetails("customerPhoneNumber", e.target.value)
              }
            />
          </div>

          <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              Enter transaction time (minutes)
            </label>

            <FormInput
              name="txnTimeMinutes"
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.txnTimeMinutes
                  : formDetails?.dropDetails?.txnTimeMinutes
              }
              valid={
                isPickupForm
                  ? formDetails?.pickupDetails?.txnTimeMinutesValid
                  : formDetails?.dropDetails?.txnTimeMinutesValid
              }
              // validationMessage={
              //   isPickupForm
              //     ? formDetails?.pickupDetails?.txnTimeMinutesValidationMessage
              //     : formDetails?.dropDetails?.txnTimeMinutesValidationMessage
              // }
              placeholder="30"
              maxLength={3}
              onChange={(e) =>
                handleSaveDetails("txnTimeMinutes", e.target.value)
              }
            />
          </div>
          {/* <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              Enter secondary number
            </label>
            <FormInput
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.customerSecondaryPhoneNumber
                  : formDetails?.dropDetails?.customerSecondaryPhoneNumber
              }
              placeholder="99999 99999"
              onChange={(e) =>
                handleSaveDetails(
                  "customerSecondaryPhoneNumber",
                  e.target.value
                )
              }
            />
          </div> */}

          <div>
            <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
              QC Url
            </label>
            <FormInput
              type="url"
              value={
                isPickupForm
                  ? formDetails?.pickupDetails?.qcUrl
                  : formDetails?.dropDetails?.qcUrl
              }
              placeholder="QC URL"
              onChange={(e) => handleSaveDetails("qcUrl", e.target.value)}
            />
          </div>
          {/* timeslot only for pickup rn */}
          {isPickupForm && (
            <div>
              <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
                Choose Slot
              </label>
              <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <Input
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                  className="tw-max-w-fit"
                  placeholder="Select date"
                  value={
                    isPickupForm
                      ? formDetails?.pickupDetails?.date
                      : formDetails?.dropDetails?.date
                  }
                  onChange={(e) => {
                    handleSaveDetails("date", e.target.value);
                  }}
                />
                <DropDownList
                  placeholder="Select time slot"
                  value={selectedTimeSlot?.label || "Time Slot"}
                  data={filterTimeSlots().map((item) => item.label)}
                  onChange={(e) => {
                    const timeSlotValue = TIME_SLOTS.find(
                      (slot) => slot.label === e.target.value
                    );
                    handleSaveDetails("timeSlot", timeSlotValue.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {/*  */}
      <h5 className="tw-text-base tw-font-semibold tw-mt-5">Checklist</h5>
      <DropDownList
        backupMessage="No Options Available"
        parentClass="tw-relative tw-w-full tw-text-sm"
        textField="checklistId"
        data={
          isPickupForm
            ? checkListData.filter((item) => item.checklistType === "PICKUP")
            : checkListData.filter((item) => item.checklistType === "DROP")
        }
        value={
          isPickupForm
            ? formDetails?.pickupDetails?.checklistId
            : formDetails?.dropDetails?.checklistId
        }
        onChange={(e) => {
          handleSaveDetails("checklistId", e.target.value);
        }}
      />

      {/*  */}
      <h5 className="tw-text-base tw-font-semibold tw-mt-5">
        Client POC details
      </h5>
      <div className="tw-grid tw-grid-cols-1 tw-gap-5 custom-border-primary tw-p-3 tw-rounded-md">
        <div>
          <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
            Client Name
          </label>
          <FormInput
            maxLength={30}
            value={
              isPickupForm
                ? formDetails?.pickupDetails?.clientName
                : formDetails?.dropDetails?.clientName
            }
            placeholder="Client name"
            onChange={(e) => handleSaveDetails("clientName", e.target.value)}
          />
        </div>
        <div>
          <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
            Client Number
          </label>
          {/* <FormInput
            value={
              isPickupForm
                ? formDetails?.pickupDetails?.clientNumber
                : formDetails?.dropDetails?.clientNumber
            }
            placeholder="Client number"
            onChange={(e) => handleSaveDetails("clientNumber", e.target.value)}
          /> */}
          <FormInput
            name="clientNumber"
            maxLength={10}
            value={
              isPickupForm
                ? formDetails?.pickupDetails?.clientNumber
                : formDetails?.dropDetails?.clientNumber
            }
            valid={
              isPickupForm
                ? formDetails?.pickupDetails?.clientNumberValid
                : formDetails?.dropDetails?.clientNumberValid
            }
            validationMessage={
              isPickupForm
                ? formDetails?.pickupDetails?.clientNumberValidationMessage
                : formDetails?.dropDetails?.clientNumberValidationMessage
            }
            placeholder="99999 99999"
            onChange={(e) => handleSaveDetails("clientNumber", e.target.value)}
          />
        </div>
      </div>
    </>
  );
}

function RadioTabs({ options = [], value, onValueChange }) {
  return (
    <div className="tw-flex tw-items-center tw-gap-4 tw-p-1 custom-border-primary tw-rounded-md tw-flex-wrap">
      {options.map((item, index) => {
        const selected = value === item?.value;
        return (
          <div
            key={index}
            style={{ flex: 1, textAlign: "center" }}
            onClick={() => onValueChange(item?.value)}
            className={`tw-text-sm tw-py-1 tw-px-3 tw-cursor-pointer tw-rounded-md ${
              selected && "tw-bg-[#00000014]"
            }`}
          >
            {item?.text}
          </div>
        );
      })}
    </div>
  );
}

export function CarDetailsForm({
  formDetails,
  setFormDetails,
  isSubmitting,
  handleAddTask,
  // handleGoBack,
}) {
  const handleSaveDetails = (key, value) => {
    setFormDetails((prev) => ({
      ...prev,
      otherDetails: {
        ...prev.otherDetails,
        [key]: value,
      },
    }));
  };
  return (
    <>
      <div className="">
        <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
          Car Type
        </label>
        <div>
          <RadioTabs
            options={CAR_TYPES}
            value={formDetails?.otherDetails?.carType}
            onValueChange={(value) => {
              handleSaveDetails("carType", value);
            }}
          />
        </div>
      </div>
      <div className="tw-mt-5">
        <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
          Transmission Type
        </label>
        <div>
          <RadioTabs
            options={TRANSMISSION_TYPES}
            value={formDetails?.otherDetails?.transmissionType}
            onValueChange={(value) => {
              handleSaveDetails("transmissionType", value);
            }}
          />
        </div>
      </div>
      <div className="tw-mt-5">
        <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
          Fuel Type
        </label>
        <div>
          <RadioTabs
            options={FUEL_TYPES}
            value={formDetails?.otherDetails?.fuelType}
            onValueChange={(value) => {
              handleSaveDetails("fuelType", value);
            }}
          />
        </div>
      </div>
      <div className="tw-my-5">
        <label className="tw-text-sm tw-block tw-leading-none tw-text-gray-700 tw-font-medium">
          Registration number (optional)
        </label>
        <div>
          <FormInput
            value={formDetails?.otherDetails?.registrationNumber}
            placeholder="Registration number"
            onChange={(e) =>
              handleSaveDetails("registrationNumber", e.target.value)
            }
          />
        </div>
      </div>

      {/* <Button
        onClick={handleGoBack}
        themeColor={null}
        className="tw-inline-block tw-mr-5 tw-mt-4 tw-w-[100px]"
      >
        Back
      </Button> */}
      <Button
        onClick={handleAddTask}
        disabled={isSubmitting}
        themeColor="primary"
        className="tw-inline-block tw-mt-4 tw-w-[150px]"
      >
        Create Task
      </Button>
    </>
  );
}
