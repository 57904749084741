import React, { useEffect, useState } from "react";
import CardLayout from "../../components/common/CardLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import LoadingPanel from "../../components/common/LoadingPanel";
import axiosService from "../../init/axios";
import { APIConfig } from "../../utils/constants/api.constants";
import { showErrorMessage, showToastMessage } from "../../utils/helpers";
import AssignDriverMap from "../../components/maps/AssignDriverMap";
import AssignDriverTable from "../../components/trip-management/assign-driver/AssignDriverTable";
import { Button } from "@progress/kendo-react-buttons";
import ModalLayout from "../../components/common/ModalLayout";

export default function AssignDriver() {
  const { tripId } = useParams();
  const [loading, setLoading] = useState({
    table: true,
    fit: true,
  });
  const [tripDetails, setTripDetails] = useState({});
  const [selectedDriverController, setSelectedDriverController] =
    useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    getTripDetails();
  }, []);
  // 1st api call
  const getTripDetails = async () => {
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.tripManagement.getTripDetails(tripId)
      )
      .then((data) => {
        setTripDetails(data.data);
        getEligibleDrivers();
      })
      .catch((error) => {
        setLoading({
          table: false,
          fit: false,
        });
        showErrorMessage(error);
      });
  };
  const [driverProfiles, setDriverProfiles] = useState({ data: [], total: 0 });
  const [modalOpen, setModalOpen] = useState(false);
  const getDriversUrl =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.driverManagement.getDriverForTrip(tripId);

  const getFitUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.getDriversTripFit(tripId);

  const assignDriverUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.allocateDriver(tripId);

  // 3rd Api call
  const getFitData = async (driverIds, data) => {
    await axiosService
      .post(getFitUrl, {
        driverIds: driverIds,
      })
      .then((e) => {
        const finalArray = data.map((item) => ({
          ...item,
          allocationFit:
            e.data.find((f) => f.driverId === item.driverId)?.allocationFit ||
            "",
        }));

        setDriverProfiles(finalArray);
        setLoading({
          table: false,
          fit: false,
        });
      })
      .catch((error) => {
        setLoading({
          table: false,
          fit: false,
        });
        showErrorMessage(error);
      });
  };

  // 2nd api call
  const getEligibleDrivers = async () => {
    await axiosService
      .get(getDriversUrl)
      .then((e) => {
        const driverIds = e.data.map((item) => item?.driverId || "");
        const data = e.data;
        setDriverProfiles(data);
        setLoading({
          table: false,
          fit: true,
        });
        getFitData(driverIds, data);
      })
      .catch((error) => {
        setLoading({
          table: false,
          fit: false,
        });
        showErrorMessage(error);
      });
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleAssignDriver = async () => {
    await axiosService
      .post(assignDriverUrl, {
        driverId: selectedDriverController.driverId,
      })
      .then((e) => {
        handleClose();
        navigate(`/home/trip/${tripId}/details`)
        showToastMessage("Assigned Driver Successfully");
      })
      .catch((error) => {
        showErrorMessage(error);
        handleClose();
      });
  };

  return (
    <CardLayout title="Assign Task">
      {loading.table ? (
        <LoadingPanel />
      ) : (
        <div className="tw-flex tw-gap-5 tw-h-full tw-w-full">
          <div className="tw-w-2/3">
            <div className="tw-flex tw-justify-between tw-gap-5 tw-items-center">
              <div>
                <Link
                  className="tw-text-black hover:tw-text-black tw-font-normal"
                  to={`/home/trip/${tripId}/details`}
                >
                  <span className="k-icon k-i-arrow-left"></span>
                </Link>
                <span className="tw-font-semibold">Trip ID: {tripId}</span>
              </div>
              <Button
                onClick={() => setModalOpen(true)}
                themeColor={"secondary"}
                disabled={!selectedDriverController}
              >
                Assign
              </Button>
            </div>

            {tripId && (
              <AssignDriverTable
                fitLoading={loading.fit}
                driverProfiles={driverProfiles}
                selectedDriverController={selectedDriverController}
                setSelectedDriverController={setSelectedDriverController}
                tripId={tripId}
              />
            )}
          </div>

          <div className="tw-w-1/3 tw-bg-gray-200 tw-h-[80dvh] tw-relative">
            {tripDetails?.tripId && (
              <AssignDriverMap
                setSelectedDriverController={setSelectedDriverController}
                tripDetails={tripDetails}
                selectedDriverController={selectedDriverController}
                driverProfiles={driverProfiles}
              />
            )}
          </div>

          {modalOpen && (
            <Modal
              driver={selectedDriverController}
              handleClose={handleClose}
              handleAssignDriver={handleAssignDriver}
            />
          )}
        </div>
      )}
    </CardLayout>
  );
}

const Modal = ({ driver, handleClose, handleAssignDriver }) => {
  return (
    <ModalLayout title={"Confirm Assigned Driver"} onClose={handleClose}>
      <div className="tw-w-[450px]">
        <p className="tw-text-base">
          Please confirm if you want to assign the following driver.
        </p>
        <h6 className="tw-font-normal tw-text-sm">{driver.fullName}</h6>
        <h6 className="tw-font-normal tw-text-sm">{driver.mobileNumber}</h6>
        <div className="tw-flex tw-mt-10 tw-justify-between">
          <Button onClick={handleAssignDriver} themeColor={"primary"}>
            Confirm
          </Button>
          <Button onClick={handleClose} themeColor={"error"}>
            Cancel
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
