import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";

const allowedCountries = ["in"];

const DestinationAutoComplete = ({ setAddress, pickup = false, address }) => {
  const [isOpen, setIsOpen] = useState(false);
  const autocompleteServiceRef = useRef(null);
  const placesServiceRef = useRef(null);
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [predictions, setPredictions] = useState([]);

  const [value, setValue] = useState({
    pickup: "",
    drop: "",
  });

  useEffect(() => {
    if (window.google) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }
  }, []);

  const debouncedFetchPredictions = useCallback(
    debounce((input) => {
      if (autocompleteServiceRef.current) {
        autocompleteServiceRef.current.getPlacePredictions(
          {
            input,
            componentRestrictions: {
              country:
                allowedCountries.length > 0 ? allowedCountries : undefined,
            },
          },
          (predictions, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const placeDetailsPromises = predictions.map(
                (prediction) =>
                  new Promise((resolve, reject) => {
                    placesServiceRef.current.getDetails(
                      { placeId: prediction.place_id },
                      (place, status) => {
                        if (
                          status ===
                          window.google.maps.places.PlacesServiceStatus.OK
                        ) {
                          resolve({
                            ...prediction,
                            coordinates: {
                              lat: place.geometry.location.lat(),
                              lng: place.geometry.location.lng(),
                            },
                            pincode:
                              place.address_components.find((component) =>
                                component.types.includes("postal_code")
                              )?.long_name || "",
                            city:
                              place.address_components.find((component) =>
                                component.types.includes("locality")
                              )?.long_name || "",
                            state:
                              place.address_components.find((component) =>
                                component.types.includes(
                                  "administrative_area_level_1"
                                )
                              )?.long_name || "",
                          });
                        } else {
                          reject(status);
                        }
                      }
                    );
                  })
              );

              Promise.all(placeDetailsPromises)
                .then((predictionsWithLatLng) => {
                  setPredictions(predictionsWithLatLng);
                })
                .catch((error) => {
                  console.error("Error fetching place details:", error);
                });
            }
          }
        );
      }
    }, 300),
    [allowedCountries]
  );

  const handleOnChange = (e) => {
    const val = e.target.value;
    setValue((prev) => ({
      ...prev,
      ...(pickup ? { pickup: val } : { drop: val }),
    }));
    if (val.length > 3) {
      setIsOpen(true);
      debouncedFetchPredictions(val);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePredictionClick = (prediction) => {
    const selectedAddress = {
      ...address,
      ...(pickup ? { pickup: prediction } : { drop: prediction }),
    };
    setAddress(selectedAddress);
    setIsOpen(false);
    setValue({
      ...(pickup ? { pickup: "" } : { drop: "" }),
    });
    setPredictions([]);
  };

  return (
    <>
      <div className="tw-relative" ref={dropdownRef}>
        <input
          style={{ width: "100%" }}
          ref={inputRef}
          autoComplete="off"
          spellCheck="false"
          name={pickup ? "pickup_location" : "drop_address"}
          type="text"
          placeholder={`Search ${pickup ? "pickup" : "drop"} location`}
          value={pickup ? value.pickup || "" : value.drop || ""}
          onChange={handleOnChange}
          className="tw-px-2 tw-py-1.5 k-input k-input-md k-rounded-md k-input-solid"
        />
        {isOpen && (
          <div className="tw-absolute tw-shadow-md tw-z-[1000] tw-left-0 tw-text-sm tw-top-9 tw-w-full k-input k-input-md k-rounded-md k-input-solid">
            <div className="tw-text-ellipsis tw-w-full tw-overflow-hidden">
              {predictions?.map((item, index) => {
                return (
                  <span
                    className="tw-p-2 tw-block tw-w-full tw-cursor-pointer hover:tw-bg-primary hover:tw-text-white tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap"
                    key={index}
                    onClick={() => handlePredictionClick(item)}
                  >
                    {item.description}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DestinationAutoComplete;
