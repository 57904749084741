import React, { useState, useEffect } from "react";
import DriverUniformAdherenceImage from "./DriverUniformAdherenceImage";
import {
  convertEnumToLabel,
  convertTo12HourFormat,
} from "../../../utils/helpers";
import { Link } from "react-router-dom";

export default function DriverAccordionNew({
  sessionDetails = {},
  isUpcoming = false,
  index,
  isOpen,
  onClick,
  getSelectedTrip,
  selectedTrip,
  setActualPathToggle,
  openChild,
  setOpenChild,
}) {
  const {
    sessionStartTime,
    sessionEndTime,
    trips = [],
    imageUrl,
    wearingUniform = false,
  } = sessionDetails;

  const sessionInfo = !isUpcoming
    ? {
        name: sessionStartTime ? "Online" : "",
        startTime: `${convertTo12HourFormat(sessionStartTime)}`,
        children: [
          ...trips.map((trip, index) => {
            const tripChildren = [];

            if (trip.driverStartedAtTime) {
              tripChildren.push({
                driverReachedAtTime: convertTo12HourFormat(
                  trip.driverReachedAtTime
                ),
                driverStartedAtTime: convertTo12HourFormat(
                  trip.driverStartedAtTime
                ),
                driverArrivalLocation: convertTo12HourFormat(
                  trip.driverArrivalLocation
                ),
              });
            }

            if (trip.actualTripStartTime || trip.expectedTripStartTime) {
              tripChildren.push({
                tripStartTitle: trip.actualTripStartTime
                  ? "Trip started at"
                  : "Expected trip start time",
                tripStartTime: convertTo12HourFormat(
                  trip.actualTripStartTime || trip.expectedTripStartTime
                ),
                tripEndTitle: trip.actualTripEndTime
                  ? "Trip ended at"
                  : "Expected trip end time",
                tripEndTime: convertTo12HourFormat(
                  trip.actualTripEndTime || trip.expectedTripEndTime
                ),
              });
            }

            if (trip.stage === "TRIP_CANCELED") {
              tripChildren.push({
                name: "Trip Cancelled",
              });
            }

            return {
              name: `Trip ${index + 1}`,
              type: "trip",
              stage: convertEnumToLabel(trip.stage),
              tripId: trip?.tripId,
              children: tripChildren,
              data: trip,
            };
          }),
          {
            name: `${sessionEndTime ? "Offline" : "Not Offline"}`,
            startTime: convertTo12HourFormat(sessionEndTime),
          },
        ],
      }
    : {
        name: `Trip ${index + 1}`,
        tripId: sessionDetails.tripId,
        type: "trip",
        children: [
          {
            tripStartTitle: sessionDetails.actualTripStartTime
              ? "Trip started at"
              : "Expected trip start time",
            tripStartTime: convertTo12HourFormat(
              sessionDetails.actualTripStartTime ||
                sessionDetails.expectedTripStartTime
            ),
            tripEndTitle: sessionDetails.actualTripEndTime
              ? "Trip ended at"
              : "Expected trip end time",
            tripEndTime: convertTo12HourFormat(
              sessionDetails.actualTripEndTime ||
                sessionDetails.forecastedEndTime ||
                sessionDetails.expectedTripEndTime
            ),
          },
        ],
        data: sessionDetails,
      };

  const handleToggle = (index, child) => {
    setActualPathToggle(false);
    setOpenChild((prevOpenChild) => {
      const newOpenChild = prevOpenChild === index ? null : index;
      if (prevOpenChild != index) {
        getSelectedTrip(child.data);
      } else {
        getSelectedTrip(null);
      }
      return newOpenChild;
    });
  };

  return (
    <div className="tree tw-flex tw-gap-5 tw-text-sm tw-justify-between tw-pr-2 tw-items-start">
      <ul className="tw-w-full tw-max-w-sm">
        <TreeNode
          node={sessionInfo}
          isOpen={isOpen}
          openChild={openChild}
          setOpenChild={setOpenChild}
          onClick={onClick}
          handleSelectedTrip={handleToggle}
        />
      </ul>

      {imageUrl && (
        <DriverUniformAdherenceImage
          status={wearingUniform ? true : false}
          basePath={imageUrl}
        />
      )}
    </div>
  );
}

const TreeNode = ({
  node,
  isOpen,
  onClick,
  handleSelectedTrip,
  openChild,
  setOpenChild,
}) => {
  return (
    <li>
      <div
        onClick={node.children && node.children.length > 0 ? onClick : null}
        className={`custom-border-primary tw-w-full tw-p-4 tw-mb-5 tw-flex tw-items-center tw-justify-between tw-rounded-md ${
          node.children && node.children.length > 0 && "tw-cursor-pointer"
        }`}
      >
        <div>
          <div className="tw-flex tw-items-center tw-gap-3 tw-font-semibold">
            {node.name === "Online" && (
              <div className="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-bg-green-500"></div>
            )}
            {node.name === "Offline" && (
              <div className="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-bg-red-500"></div>
            )}
            {node.name === "Trip Cancelled" && (
              <div className="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-bg-orange-500"></div>
            )}
            {node.type === "trip" && (
              <div className="tw-h-[10px] tw-w-[10px] tw-rounded-full tw-bg-blue-500"></div>
            )}
            <div>
              {node.name}
              <span className="tw-block tw-opacity-60 tw-text-xs">
                {node.startTime}
              </span>
              {node.tripId && (
                <Link
                  to={`/home/trip/${node.tripId}/details`}
                  className="tw-block tw-mt-1 tw-opacity-80 tw-text-xs"
                >
                  {node.tripId}
                </Link>
              )}
            </div>
          </div>
          {node.driverStartedAtTime && (
            <div>
              <span className="tw-block tw-font-medium">Driver started at</span>
              <span className="tw-block tw-opacity-60 tw-text-xs">
                {node.driverStartedAtTime}
              </span>
              <span className="tw-block tw-font-medium tw-mt-2">
                Driver reached at
              </span>
              <span className="tw-block tw-opacity-60 tw-text-xs">
                {node.driverReachedAtTime}
              </span>
            </div>
          )}
          {node.tripStartTime && (
            <div>
              <span className="tw-block tw-font-medium">
                {node.tripStartTitle}
              </span>
              <span className="tw-block tw-opacity-60 tw-text-xs">
                {node.tripStartTime}
              </span>
              <span className="tw-block tw-font-medium tw-mt-2">
                {node.tripEndTitle}
              </span>
              <span className="tw-block tw-opacity-60 tw-text-xs">
                {node.tripEndTime}
              </span>
            </div>
          )}
        </div>

        {node.children && node.children.length > 0 && (
          <span>{!isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}</span>
        )}
      </div>
      {node.children && node.children.length > 0 && isOpen && (
        <ul>
          {node.children.map((child, index) => (
            <TreeNode
              key={index}
              node={child}
              isOpen={index === openChild}
              onClick={() => handleSelectedTrip(index, child)}
              handleSelectedTrip={handleSelectedTrip}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

function ChevronDownIcon({ fill = "#101010" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6465 5.64586C12.693 5.5994 12.7481 5.56255 12.8088 5.53741C12.8695 5.51227 12.9346 5.49933 13.0003 5.49933C13.066 5.49933 13.131 5.51227 13.1917 5.53741C13.2524 5.56255 13.3076 5.5994 13.354 5.64586C13.4005 5.69231 13.4373 5.74746 13.4625 5.80816C13.4876 5.86885 13.5006 5.93391 13.5006 5.99961C13.5006 6.0653 13.4876 6.13036 13.4625 6.19105C13.4373 6.25175 13.4005 6.3069 13.354 6.35336L8.35403 11.3534C8.30759 11.3998 8.25245 11.4367 8.19175 11.4619C8.13105 11.487 8.06599 11.5 8.00028 11.5C7.93457 11.5 7.86951 11.487 7.80881 11.4619C7.74811 11.4367 7.69296 11.3998 7.64653 11.3534L2.64653 6.35336C2.55271 6.25954 2.5 6.13229 2.5 5.99961C2.5 5.86692 2.55271 5.73968 2.64653 5.64586C2.74035 5.55204 2.8676 5.49933 3.00028 5.49933C3.13296 5.49933 3.26021 5.55204 3.35403 5.64586L8.00028 10.2927L12.6465 5.64586Z"
        fill={fill}
      />
    </svg>
  );
}

function ChevronUpIcon({ fill = "#101010" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.35347 10.3541C3.30702 10.4006 3.25187 10.4374 3.19117 10.4626C3.13047 10.4877 3.06542 10.5007 2.99972 10.5007C2.93402 10.5007 2.86897 10.4877 2.80827 10.4626C2.74758 10.4374 2.69243 10.4006 2.64597 10.3541C2.59952 10.3077 2.56267 10.2525 2.53752 10.1918C2.51238 10.1311 2.49944 10.0661 2.49944 10.0004C2.49944 9.9347 2.51238 9.86964 2.53752 9.80894C2.56267 9.74825 2.59952 9.6931 2.64597 9.64664L7.64597 4.64664C7.69241 4.60015 7.74755 4.56328 7.80825 4.53811C7.86895 4.51295 7.93401 4.5 7.99972 4.5C8.06543 4.5 8.13049 4.51295 8.19119 4.53811C8.25189 4.56328 8.30704 4.60015 8.35347 4.64664L13.3535 9.64664C13.4473 9.74046 13.5 9.86771 13.5 10.0004C13.5 10.1331 13.4473 10.2603 13.3535 10.3541C13.2597 10.448 13.1324 10.5007 12.9997 10.5007C12.867 10.5007 12.7398 10.448 12.646 10.3541L7.99972 5.70727L3.35347 10.3541Z"
        fill={fill}
      />
    </svg>
  );
}
