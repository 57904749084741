import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { SignIn } from "./pages/SignIn.jsx";
import { Home } from "./pages/Home.jsx";
import TripManagement from "./pages/trip-management/TripManagement.jsx";
import DriverManagement from "./pages/DriverManagement.jsx";
import PricingManagement from "./pages/PricingManagement.jsx";
import { getFromLocalStorage } from "./utils/helpers.js";

import BulkOperationsManagement from "./pages/BulkOperationsManagement.jsx";
import CustomerManagement from "./pages/CustomerManagement.jsx";
import DriverPayoutManagement from "./pages/DriverPayoutManagement.jsx";
import GrievanceCouponManagement from "./pages/coupons/GrievanceCouponManagement.jsx";
import ReferralCouponManagement from "./pages/coupons/ReferralCouponManagement.jsx";
import AssignDriver from "./pages/trip-management/AssignDriver.jsx";
import SubscriptionPackage from "./pages/SubscriptionPackage.jsx";
import SubscriptionDetails from "./components/subscription-package/SubscriptionDetails.jsx";
import AllDriverManagement from "./pages/AllDriverManagement.jsx";
import SubscriptionCycleDetails from "./components/subscription-package/SubscriptionCycleDetails.jsx";
import SubscriptionCycleDayDetails from "./components/subscription-package/SubscriptionCycleDayDetails.jsx";

// const TripComponent = lazy(() => import('./pages/TripManagement.jsx'));
// const DriverComponent = lazy(() => import('./pages/DriverManagement.jsx'));
// const PricingComponent = lazy(() => import('./pages/PricingManagement.jsx'));

export const routeConfig = [
  {
    path: "/home/trip",
    component: TripManagement,
  },
  {
    path: "/home/trip/:tripId/details/",
    component: TripManagement,
  },
  {
    path: "/home/trip/:tripId/assign-driver/",
    component: AssignDriver,
  },
  {
    path: "/home/driver",
    component: DriverManagement,
  },
  {
    path: "/home/driver/all",
    component: AllDriverManagement,
  },
  {
    path: "/home/driver/:driverId/profile",
    component: DriverManagement,
  },
  {
    path: "/home/driver/:driverId/account",
    component: DriverManagement,
  },
  {
    path: "/home/driver/:driverId/online-activity",
    component: DriverManagement,
  },
  {
    path: "/home/driver/payout",
    component: DriverPayoutManagement,
  },
  {
    path: "/home/pricing",
    component: PricingManagement,
  },
  {
    path: "/home/coupon/grievance",
    component: GrievanceCouponManagement,
  },
  {
    path: "/home/coupon/referral",
    component: ReferralCouponManagement,
  },
  {
    path: "/home/customer-management",
    component: CustomerManagement,
  },
  {
    path: "/home/operations",
    component: BulkOperationsManagement,
  },
  {
    path: "/home/subscriptions",
    component: SubscriptionPackage,
  },
  {
    path: "/home/subscriptions/:subId/details",
    component: SubscriptionDetails,
  },
  {
    path: "/home/subscriptions/:subId/cycle/:cycleId/details",
    component: SubscriptionCycleDetails,
  },
  {
    path: "/home/subscriptions/:subId/cycle/:cycleId/day/:tripId/details",
    component: SubscriptionCycleDayDetails,
  },
];

const Routing = () => {
  // const isAuthenticated = useSelector(state => state.auth.userLoggedIn);
  const isAuthenticated = getFromLocalStorage("userLoggedIn");

  return (
    <Routes>
      <Route
        path="/login"
        element={
          isAuthenticated ? <Navigate to="/home/trip" replace /> : <SignIn />
        }
      />
      <Route
        path="/"
        element={
          <Navigate to={isAuthenticated ? "/home/trip" : "/login"} replace />
        }
      />
      <Route
        path="/home"
        element={isAuthenticated ? <Home /> : <Navigate to="/login" replace />}
      >
        {routeConfig.map((route) => {
          const Element = route.component;
          return (
            <Route
              {...route}
              key={route.path}
              element={isAuthenticated ? <Element /> : <Navigate to="/login" />}
            />
          );
        })}
      </Route>
      <Route
        path="*"
        element={
          <Navigate to={isAuthenticated ? "/home/trip" : "/login"} replace />
        }
      />
    </Routes>
  );
};

export default Routing;
