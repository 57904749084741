import React, { useEffect, useState } from "react";
import CardLayout from "../common/CardLayout";
import { APIConfig } from "../../utils/constants/api.constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  conditionalRendering,
  getLabelByValue,
  showErrorMessage,
  timeConverter,
} from "../../utils/helpers";
import axiosService from "../../init/axios";
import LoadingSpinner from "../common/LoadingSpinner";
import CandidateDriverModal from "./CandidateDriverModal";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { SubscriptionConfig } from "../../config/subscriptionConfig";

export default function SubscriptionDetails() {
  const { subId } = useParams();
  const navigate = useNavigate();

  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.subscription.getSubscriptionDetails(subId);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const getSubscriptionData = async () => {
    setLoading(true);
    await axiosService
      .get(baseUrl)
      .then((data) => {
        setData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  useEffect(() => {
    if (subId) {
      getSubscriptionData();
    }
  }, [subId]);

  const handleOnClose = ({ trigger = false }) => {
    setModalOpen(false);
    if (!trigger) return;
    getSubscriptionData();
  };
  return (
    <CardLayout title={"Subscriptions"}>
      {loading ? (
        <div className="tw-min-h-[80dvh] tw-grid tw-place-content-center">
          <LoadingSpinner color="#d5d5d5" />
        </div>
      ) : (
        <>
          <section className="tw-space-y-10">
            <div className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-gap-5 tw-items-center">
                <span
                  className="k-icon k-i-arrow-left"
                  onClick={() => navigate("/home/subscriptions")}
                ></span>
                <h4 className="tw-text-lg tw-mt-2">Package ID : {data?.id}</h4>
                <span className={`status-pill ${data?.status}`}>
                  {getLabelByValue(data?.status, SubscriptionConfig.status) ||
                    "N.A."}
                </span>
              </div>
              {conditionalRendering(
                ["SEARCHING_FOR_DRIVER", "CHANGE_DRIVER"],
                data?.status,
                <Button
                  themeColor={"primary"}
                  onClick={() => setModalOpen(true)}
                >
                  {data?.driverName ? "Reassign Driver" : "Assign Driver"}
                </Button>
              )}
            </div>

            <div className="tw-grid tw-grid-cols-2 tw-gap-10">
              <Grouping
                title="Customer Details"
                className={"columns-two"}
                details={[
                  {
                    label: "Customer Name",
                    value: data?.customerName || "N.A.",
                  },
                  {
                    label: "Customer Number",
                    value: data?.customerMobile || "N.A.",
                  },
                ]}
              />
              <Grouping
                title="Driver Details"
                className={"columns-two"}
                details={[
                  {
                    label: "Driver Name",
                    value: data?.driverName || "N.A.",
                  },
                  {
                    label: "Driver Number",
                    value: data?.driverMobile || "N.A.",
                  },
                ]}
              />
            </div>
            <div>
              <Grouping
                title="Package details"
                className={"columns-four"}
                details={[
                  {
                    label: "Package days:",
                    value: data?.noOfDays || "N.A.",
                  },
                  {
                    label: "Consumed days:",
                    value: data?.dayConsumed || "N.A.",
                  },
                  {
                    label: "Package hours:",
                    value: data?.noOfHours || "N.A.",
                  },
                  {
                    label: "Package start date:",
                    value: timeConverter(data?.startTime) || "N.A.",
                  },
                ]}
              />
            </div>

            <div className="custom-template">
              <h5 className="tw-font-medium tw-text-base">
                Subscription cycle
              </h5>

              <Grid
                onRowDoubleClick={(item) =>
                  navigate(
                    `/home/subscriptions/${subId}/cycle/${item.dataItem.id}/details`
                  )
                }
                data={data?.cycles}
              >
                <Column title="Cycle" field="name" />
                <Column
                  title="Status"
                  field="status"
                  cell={(e) => {
                    return (
                      <td>
                        {getLabelByValue(
                          e.dataItem?.status,
                          SubscriptionConfig.cycleStatus
                        ) || "N.A."}
                      </td>
                    );
                  }}
                />
                <Column
                  field="startTime"
                  title="Cycle Start Date"
                  cell={(e) => <td>{timeConverter(e?.dataItem?.startTime)}</td>}
                />
                <Column
                  field="endTime"
                  title="Cycle End Date"
                  cell={(e) => <td>{timeConverter(e?.dataItem?.endTime)}</td>}
                />
                <Column
                  field="paymentStatus"
                  title="Payment status"
                  cell={(e) => {
                    return (
                      <td>
                        {getLabelByValue(
                          e.dataItem?.paymentStatus,
                          SubscriptionConfig.paymentStatus
                        ) || "N.A."}
                      </td>
                    );
                  }}
                />
              </Grid>
            </div>
          </section>
        </>
      )}

      {modalOpen && (
        <CandidateDriverModal packageid={data?.id} onClose={handleOnClose} />
      )}
    </CardLayout>
  );
}

export const Grouping = ({ title, details, className }) => (
  <div>
    <h5 className="tw-font-medium tw-text-base">{title}</h5>
    <div
      className={`custom-border-primary tw-p-5 tw-pb-4 tw-rounded-md ${className}`}
    >
      {details.map((item, index) => (
        <div key={index}>
          <h6 className="tw-font-normal tw-text-sm tw-text-[#919191] tw-leading-none">
            {item?.label}
          </h6>
          <h6 className="tw-font-medium tw-text-base tw-text-[#262626] tw-leading-none">
            {item?.value}
          </h6>
        </div>
      ))}
    </div>
  </div>
);
