import React from "react";

export default function ToggleSwitch({
  checked,
  onChange,
  bgColor = "#ff6358",
  btn = "#fff",
  defaultBgColor = "#00000014",
}) {
  const id = Math.random();
  return (
    <>
      <input
        hidden
        className="tw-peer tw-block"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={"key" + id}
      />
      <label
        className="tw-cursor-pointer tw-items-center tw-flex tw-relative tw-w-10 tw-h-5 tw-rounded-full"
        style={{ background: checked ? bgColor : defaultBgColor }}
        htmlFor={"key" + id}
      >
        <div
          style={{ background: btn }}
          className={`tw-rounded-full tw-w-4 tw-absolute tw-aspect-square tw-duration-300 tw-transition-all ${
            checked ? "tw-right-0.5" : " tw-left-0.5"
          }`}
        ></div>
      </label>
    </>
  );
}
