import React, { useEffect, useState } from "react";
import CardLayout from "../common/CardLayout";
import { APIConfig } from "../../utils/constants/api.constants";
import { useNavigate, useParams } from "react-router-dom";
import {
  calculateTripDuration,
  conditionalRendering,
  convertTo12HourFormat,
  formatDate,
  getLabelByValue,
  getObjectByKey,
  showErrorMessage,
  timeConverter,
} from "../../utils/helpers";
import axiosService from "../../init/axios";
import LoadingSpinner from "../common/LoadingSpinner";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { Grouping } from "./SubscriptionDetails";
import { Button } from "@progress/kendo-react-buttons";
import CandidateDriverModal from "./CandidateDriverModal";

export default function SubscriptionCycleDayDetails() {
  const { subId, cycleId, tripId } = useParams();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const baseUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.subscription.getSubscriptionCycleDayDetails(subId, tripId);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getSubscriptionCycleData = async () => {
    setLoading(true);
    await axiosService
      .get(baseUrl)
      .then((data) => {
        setData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };

  useEffect(() => {
    if (subId && tripId) {
      getSubscriptionCycleData();
    }
  }, [subId, tripId]);

  const handleOnClose = ({ trigger = false }) => {
    setModalOpen(false);
    if (!trigger) return;
    getSubscriptionCycleData();
  };
  return (
    <CardLayout title={"Subscriptions"}>
      {loading ? (
        <div className="tw-min-h-[80dvh] tw-grid tw-place-content-center">
          <LoadingSpinner color="#d5d5d5" />
        </div>
      ) : (
        <>
          <section className="tw-space-y-10">
            <div className="tw-flex tw-items-center tw-justify-between">
              <div className="tw-flex tw-gap-5 tw-items-center">
                <span
                  className="k-icon k-i-arrow-left"
                  onClick={() =>
                    navigate(
                      `/home/subscriptions/${subId}/cycle/${cycleId}/details`
                    )
                  }
                ></span>
                <h4 className="tw-text-lg tw-mt-2 tw-flex tw-items-center tw-gap-4">
                  Trip Id : {data?.tripEntity.tripId}{" "}
                  <span className="tw-inline-block tw-bg-black tw-h-1.5 tw-w-1.5 tw-rounded-full"></span>
                  {formatDate(data?.tripEntity?.expectedTripStartTime) || ""}
                </h4>
                {data?.tripEntity?.stage === "TRIP_COMPLETED" && (
                  <div className="tw-px-4 tw-py-1 tw-text-[#14561B] tw-text-center tw-text-xs tw-rounded-full tw-bg-[#D5FDD9]">
                    Completed
                  </div>
                )}
                {data?.tripEntity?.stage === "DRIVER_NOT_AVAILABLE" && (
                  <div className="tw-px-4 tw-py-1 tw-text-[#184C4F] tw-text-center tw-text-xs tw-rounded-full tw-bg-[#D5FBFD]">
                    Day Off
                  </div>
                )}
              </div>
              {/* <div>
                {conditionalRendering(
                  ["DRIVER_NOT_AVAILABLE"],
                  data?.tripEntity?.stage,
                  <Button
                    themeColor={"primary"}
                    onClick={() => setModalOpen(true)}
                  >
                    {data?.driverName ? "Reassign Driver" : "Assign Driver"}
                  </Button>
                )}
              </div> */}
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-gap-10">
              <Grouping
                title="Customer Details"
                className={"columns-two"}
                details={[
                  {
                    label: "Customer Name",
                    value: data?.subscription?.customerName || "N.A.",
                  },
                  {
                    label: "Customer Number",
                    value: data?.subscription?.customerMobile || "N.A.",
                  },
                ]}
              />
              <Grouping
                title="Driver Details"
                className={"columns-two"}
                details={[
                  {
                    label: "Driver Name",
                    value: data?.tripEntity?.driverDetails?.fullName || "N.A.",
                  },
                  {
                    label: "Driver Number",
                    value: data?.tripEntity?.driverMobile || "N.A.",
                  },
                ]}
              />
            </div>
            <div>
              <Grouping
                title="Package details"
                className={"columns-four"}
                details={[
                  {
                    label: "Package days:",
                    value: data?.subscription?.noOfDays || "N.A.",
                  },
                  {
                    label: "Consumed days:",
                    value: data?.subscription?.dayConsumed || "N.A.",
                  },
                  {
                    label: "Package hours:",
                    value: data?.subscription?.noOfHours || "N.A.",
                  },
                  {
                    label: "Package start date:",
                    value:
                      timeConverter(data?.subscription?.startTime) || "N.A.",
                  },
                ]}
              />
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-gap-10">
              <Grouping
                title="Operational details"
                className={"columns-three"}
                details={[
                  {
                    label: "Service Time:",
                    value:
                      calculateTripDuration(
                        data?.tripEntity?.actualTripStartTime,
                        data?.tripEntity?.actualTripEndTime
                      ) || "N.A.",
                  },
                  {
                    label: "Start Time:",
                    value:
                      convertTo12HourFormat(
                        data?.tripEntity?.actualTripStartTime
                      ) || "N.A.",
                  },
                  {
                    label: "End time:",
                    value:
                      convertTo12HourFormat(
                        data?.tripEntity?.actualTripEndTime
                      ) || "N.A.",
                  },
                ]}
              />
              <Grouping
                title="Additional Charges (₹)"
                className={"columns-three"}
                details={[
                  {
                    label: "Early Pickup Charges:",
                    value:
                      getObjectByKey(
                        data?.paymentHistory,
                        "chargeType",
                        "Early Pickup Charges"
                      )?.basePrice || "N.A.",
                  },
                  {
                    label: "Overtime Charges:",
                    value:
                      getObjectByKey(
                        data?.paymentHistory,
                        "chargeType",
                        "Overtime Charges"
                      )?.basePrice || "N.A.",
                  },
                  {
                    label: "Additional Night Charges:",
                    value:
                      getObjectByKey(
                        data?.paymentHistory,
                        "chargeType",
                        "Additional Night Charges"
                      )?.basePrice || "N.A.",
                  },
                ]}
              />
            </div>
            {data?.tripEntity?.summonList?.length > 0 && (
              <div className="custom-template">
                <h5 className="tw-font-medium tw-text-base">Summons</h5>
                <Grid data={data?.tripEntity?.summonList}>
                  <Column
                    title="Requested at"
                    field="createdAt"
                    cell={(e) => (
                      <td>{convertTo12HourFormat(e.dataItem.createdAt)}</td>
                    )}
                  />
                  <Column title="Status" field="status" />
                  <Column
                    title="Customer location"
                    field={"pickUpAddress.subHeading"}
                  />
                </Grid>
              </div>
            )}
          </section>

          {modalOpen && (
            <CandidateDriverModal
              tripId={tripId}
              packageid={data?.subscription?.id}
              onClose={handleOnClose}
            />
          )}
        </>
      )}
    </CardLayout>
  );
}
