import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  logIn,
  setToken,
  setUserDetails,
  setUserRoles,
} from "../slices/authSlice";
import { saveToLocalStorage, showErrorMessage } from "../utils/helpers";
import { Descope } from "@descope/react-sdk";
import logo from "../assets/logo.svg";
import cover from "../assets/cover.webp";

export const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLoginSuccess = (e) => {
    const { refreshJwt, sessionJwt, user } = e?.detail;
    saveToLocalStorage("token", sessionJwt);
    saveToLocalStorage("refreshToken", refreshJwt);
    saveToLocalStorage("userLoggedIn", true);
    saveToLocalStorage("userDetails", user);
    dispatch(logIn());
    dispatch(setToken(sessionJwt));
    dispatch(setUserDetails(user));
    dispatch(
      setUserRoles(
        user?.userTenants.filter(
          (tenant) =>
            tenant?.tenantId === process.env.REACT_APP_DESCOPE_TENANT_ID
        )[0]?.roleNames
      )
    );
    navigate("/home/trip");
  };

  const onLoginFail = (e) => {
    showErrorMessage("Login Failed");
  };

  return (
    <div className="App tw-grid tw-grid-cols-2 tw-min-h-screen">
      <section className="tw-bg-black">
        <img
          src={cover}
          alt="cover"
          className="tw-w-full tw-h-full tw-object-cover"
        />
      </section>

      <section className="tw-grid tw-place-content-center">
        <div>
          <img
            src={logo}
            alt="Pathfinder"
            className="tw-w-60 tw-mx-auto tw-block"
          />
          <h5 className="tw-text-black tw-text-center tw-mt-5 tw-mb-10 tw-font-semibold">LOG IN</h5>
          <div className="tw-min-w-[400px]">
            <Descope
              flowId={process.env.REACT_APP_DESCOPE_SIGN_IN_FLOW_ID}
              onSuccess={onLoginSuccess}
              onError={onLoginFail}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
