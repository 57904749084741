import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from "react";
import GoogleMapsWrapper from "./GoogleMapsWrapper";
import { mapDefaults } from "./defaults";
import startPointSymbol from "../../assets/start-point-2.png";
import endPointSymbol from "../../assets/end-point-2.png";

const ConfirmationDialog = ({ show, onConfirm, onCancel }) => {
  if (!show) return null;
  return (
    <div className="tw-absolute tw-inset-0 tw-bg-black/20 tw-backdrop-blur-sm tw-flex tw-justify-center tw-items-center">
      <div className="tw-bg-white tw-p-4 tw-rounded-md tw-text-xs">
        <p>Do you want to confirm the new position?</p>
        <div className="tw-flex tw-justify-end tw-gap-2">
          <button
            className="tw-bg-red-500 tw-text-white tw-px-3 tw-py-1.5 tw-rounded-md tw-border-none"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="tw-bg-green-500 tw-text-white tw-px-3 tw-py-1.5 tw-rounded-md tw-border-none"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

function AddTaskMap({ initialPickup, initialDrop, setAddress }) {
  const containerStyle = { width: "100%", height: "100%" };
  const mapRef = useRef(null);
  const pickupMarkerRef = useRef(null);
  const dropMarkerRef = useRef(null);
  const polylineRef = useRef(null);
  const [dragConfirmation, setDragConfirmation] = useState(false);
  const [draggingMarker, setDraggingMarker] = useState(null);
  const [previousPosition, setPreviousPosition] = useState(null);
  const [newPosition, setNewPosition] = useState(null);
  const center = useMemo(() => mapDefaults.addTaskMap.center, []);
  const options = useMemo(() => mapDefaults.addTaskMap.options, []);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const createMarker = useCallback((position, icon, draggable = false) => {
    return new window.google.maps.Marker({
      position,
      map: mapRef.current,
      icon: {
        url: icon,
        scaledSize: new window.google.maps.Size(30, 30),
      },
      draggable,
    });
  }, []);

  const updateMarkerPosition = useCallback((marker, position) => {
    marker.setPosition(position);
    mapRef.current.panTo(position);
  }, []);

  const removeMarker = useCallback((markerRef) => {
    if (markerRef.current) {
      markerRef.current.setMap(null);
      markerRef.current = null;
    }
  }, []);

  const geocodeLatLng = useCallback((lat, lng) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat, lng };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            resolve(results[0]);
          } else {
            resolve({ description: "No results found" });
          }
        } else {
          reject("Geocoder failed due to: " + status);
        }
      });
    });
  }, []);

  const createPolyline = useCallback(() => {
    if (pickupMarkerRef.current && dropMarkerRef.current) {
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
      }

      const path = [
        pickupMarkerRef.current.getPosition(),
        dropMarkerRef.current.getPosition(),
      ];

      polylineRef.current = new window.google.maps.Polyline({
        path,
        geodesic: true,
        strokeColor: "#000",
        strokeOpacity: 0,
        icons: [
          {
            icon: {
              path: "M 0,-1 0,1",
              strokeOpacity: 1,
              scale: 4,
            },
            offset: "0",
            repeat: "20px",
          },
        ],
        map: mapRef.current,
      });
    }
  }, []);

  const removePolyline = useCallback(() => {
    if (polylineRef.current) {
      polylineRef.current.setMap(null);
      polylineRef.current = null;
    }
  }, []);
  const recenterMap = useCallback(() => {
    if (!pickupMarkerRef.current || !dropMarkerRef.current) return null;
    const bounds = new window.google.maps.LatLngBounds();

    if (pickupMarkerRef.current) {
      bounds.extend(pickupMarkerRef.current.getPosition());
    }
    if (dropMarkerRef.current) {
      bounds.extend(dropMarkerRef.current.getPosition());
    }

    mapRef.current.fitBounds(bounds);
  }, []);

  const handleDragEnd = async (marker, type) => {
    const newPosition = marker.getPosition();
    setDraggingMarker(marker);
    setPreviousPosition(marker.getPosition());
    setNewPosition(newPosition);
    setDragConfirmation(true);
  };

  const confirmDragEnd = async () => {
    setDragConfirmation(false);
    const result = await geocodeLatLng(newPosition.lat(), newPosition.lng());

    let pincode = "";
    if (result.address_components) {
      const postalCodeComponent = result.address_components.find((component) =>
        component.types.includes("postal_code")
      );
      if (postalCodeComponent) {
        pincode = postalCodeComponent.long_name;
      }
    }

    setAddress((prev) => ({
      ...prev,
      [draggingMarker === pickupMarkerRef.current ? "pickup" : "drop"]: {
        description: result.formatted_address,
        coordinates: { lat: newPosition.lat(), lng: newPosition.lng() },
        place_id: result.place_id,
        reference: result.place_id,
        structured_formatting: {
          main_text:
            result.address_components.find((component) =>
              component.types.includes("locality")
            )?.long_name || "",
          secondary_text:
            result.address_components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name || "",
        },
        terms: result.address_components.map((component) => ({
          offset: 0,
          value: component.long_name,
        })),
        types: result.types,
        pincode,
        city:
          result.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name || "",
        state:
          result.address_components.find((component) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name || "",
      },
    }));
    createPolyline();
  };

  const cancelDragEnd = () => {
    setDragConfirmation(false);
    draggingMarker.setPosition(previousPosition);
  };

  useEffect(() => {
    if (initialPickup && initialPickup.coordinates) {
      const { lat, lng } = initialPickup.coordinates;
      const position = new window.google.maps.LatLng(lat, lng);
      if (pickupMarkerRef.current) {
        updateMarkerPosition(pickupMarkerRef.current, position);
      } else {
        pickupMarkerRef.current = createMarker(
          position,
          startPointSymbol,
          true
        );
        mapRef.current.panTo(position);

        pickupMarkerRef.current.addListener("dragend", () =>
          handleDragEnd(pickupMarkerRef.current, "pickup")
        );
      }
    } else {
      removeMarker(pickupMarkerRef);
    }
  }, [
    initialPickup,
    createMarker,
    updateMarkerPosition,
    handleDragEnd,
    removeMarker,
  ]);

  useEffect(() => {
    if (initialDrop && initialDrop.coordinates) {
      const { lat, lng } = initialDrop.coordinates;
      const position = new window.google.maps.LatLng(lat, lng);

      if (dropMarkerRef.current) {
        updateMarkerPosition(dropMarkerRef.current, position);
      } else {
        dropMarkerRef.current = createMarker(position, endPointSymbol, true);
        mapRef.current.panTo(position);

        dropMarkerRef.current.addListener("dragend", () =>
          handleDragEnd(dropMarkerRef.current, "drop")
        );
      }
    } else {
      removeMarker(dropMarkerRef);
    }
  }, [
    initialDrop,
    createMarker,
    updateMarkerPosition,
    handleDragEnd,
    removeMarker,
  ]);

  useEffect(() => {
    if (initialPickup && initialDrop) {
      createPolyline();
    } else {
      removePolyline();
    }
  }, [initialPickup, initialDrop, createPolyline, removePolyline]);

  return (
    <>
      <svg
        className="tw-scale-75 tw-cursor-pointer tw-absolute tw-border-none tw-top-2.5 tw-right-2.5 tw-z-[1]"
        onClick={recenterMap}
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
      >
        <path d="M24 16c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm17.88 6C40.96 13.66 34.34 7.04 26 6.12V2h-4v4.12C13.66 7.04 7.04 13.66 6.12 22H2v4h4.12c.92 8.34 7.54 14.96 15.88 15.88V46h4v-4.12c8.34-.92 14.96-7.54 15.88-15.88H46v-4h-4.12zM24 38c-7.73 0-14-6.27-14-14s6.27-14 14-14 14 6.27 14 14-6.27 14-14 14z"></path>
      </svg>

      <GoogleMapsWrapper
        containerStyle={containerStyle}
        options={options}
        center={center}
        zoom={mapDefaults.addTaskMap.zoom}
        onLoad={onLoad}
        mapRef={mapRef.current}
      />
      <ConfirmationDialog
        show={dragConfirmation}
        onConfirm={confirmDragEnd}
        onCancel={cancelDragEnd}
      />
    </>
  );
}

export default React.memo(AddTaskMap);
