import React, { useState } from "react";
import ModalLayout from "../common/ModalLayout";
import { APIConfig } from "../../utils/constants/api.constants";
import axiosService from "../../init/axios";

import {
  CategoryFilterCell,
  getLabelFromEnum,
  initialFilter,
  showErrorMessage,
  showToastMessage,
  stringToSentenceCase,
} from "../../utils/helpers";

import { useSelector } from "react-redux";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import GridLoader from "../common/GridLoader";
import { Button } from "@progress/kendo-react-buttons";

export default function CandidateDriverModal({
  onClose,
  packageid,
  tripId = null,
}) {
  const baseUrl =
    process.env.REACT_APP_DRIVER_BASE_URL +
    APIConfig.subscription.getAllDriversSubscriptions;

  const selectedSubscriptionRegionFilters = useSelector(
    (state) => state.region?.subscription
  );

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    otherParams: selectedSubscriptionRegionFilters
      ? {
          region_id: selectedSubscriptionRegionFilters.regionId + ":in:string",
        }
      : {},
  });
  const [driverProfiles, setDriverProfiles] = useState({
    data: [],
    total: 0,
  });

  const [selectedDriver, setSelectedDriver] = useState({
    subscriptionId: "",
    driverId: "",
  });

  const columns = [
    {
      id: "select",
      field: "select",
      title: "Select",
      filterable: false,
      width: 100,
      cell: (e) => (
        <td>
          <span
            className={`tw-w-4 tw-h-4 tw-rounded-full custom-border-primary tw-block mx-auto ${
              selectedDriver?.driverId === e.dataItem.driverId &&
              "tw-bg-primary"
            }`}
          ></span>
        </td>
      ),
    },
    {
      id: "fullName",
      field: "fullName",
      title: "Driver Name",
      filterable: true,
      filterKey: "full_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 300,
    },
    {
      id: "mobileNumber",
      field: "mobileNumber",
      title: "Driver Number",
      filterable: true,
      filterKey: "mobile_number",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
  ];

  const dataStateChange = (e) => {
    if (selectedSubscriptionRegionFilters) {
      setDataState({
        ...e.dataState,
        otherParams: {
          region_id: selectedSubscriptionRegionFilters.regionId + ":in:string",
        },
      });
    } else {
      if (
        dataState?.otherParams &&
        Object.keys(dataState?.otherParams)?.length > 0
      ) {
        setDataState({
          ...e.dataState,
          otherParams: {},
        });
      } else {
        setDataState(e.dataState);
      }
    }
  };

  const dataReceived = (dataObj) => {
    const arr = dataObj.data.data.map((driver) => {
      const updatedProfileDetails = getLabelFromEnum(
        ["bgvStatus", "testDriveStatus", "onboardingStage", "driverPayoutType"],
        driver,
        "driver"
      );
      let driverObj = {};
      if (driver?.fullName) {
        driverObj = {
          ...driverObj,
          fullName: stringToSentenceCase(driver?.fullName),
        };
      } else {
        driverObj = { ...driverObj, fullName: "" };
      }
      if (driver?.regionIds && driver?.regionIds?.length > 0) {
        const regionIdStr = driver?.regionIds
          .reduce((acc, curr) => (acc = acc + curr + ", "), "")
          .slice(0, -2);
        driverObj = { ...driverObj, regionId: regionIdStr };
      }
      return {
        ...updatedProfileDetails,
        ...driverObj,
        lastLocation: driver.lastKnownLat + "," + driver.lastKnownLng,
      };
    });
    setDriverProfiles({ ...dataObj, data: arr });
  };
  const handleRowClick = (e) => {
    let driver = e.dataItem;
    const payload = {
      subscriptionId: packageid,
      driverId: driver.driverId,
      ...(tripId && { tripId: tripId }),
    };
    setSelectedDriver(payload);
  };
  const handleCandidateAssign = async () => {
    await axiosService
      .post(
        process.env.REACT_APP_TRIP_BASE_URL +
          APIConfig.subscription.assignDriverSubscriptionLevel,
        selectedDriver
      )
      .then((data) => {
        showToastMessage(data.data.msg);
        onClose({ trigger: true });
      })
      .catch((e) => showErrorMessage(e));
  };
  return (
    <ModalLayout title={"Assign Candidate Driver"} onClose={onClose}>
      <Button
        disabled={!selectedDriver.driverId}
        onClick={handleCandidateAssign}
        className="tw-ml-auto tw-block"
        themeColor={"primary"}
      >
        Candidate Assign
      </Button>
      <div className="tw-w-[600px] tw-mt-5 tw-relative">
        <Grid
          filter={initialFilter}
          filterable={true}
          sortable={false}
          pageable={true}
          {...dataState}
          data={driverProfiles}
          onDataStateChange={dataStateChange}
          onRowClick={handleRowClick}
        >
          {columns.map((column) => (
            <Column
              key={column?.id}
              field={column?.field}
              title={column?.title}
              width={column?.width}
              filterable={column?.filterable}
              cell={column?.cell}
              filterCell={
                column.defaultFilterOperator === "eq" &&
                ((props) => CategoryFilterCell(props, column, 250))
              }
            />
          ))}
        </Grid>
        <GridLoader
          loadingDisabled
          baseUrl={baseUrl}
          columns={columns}
          dataState={dataState}
          onDataReceived={dataReceived}
        />
      </div>
    </ModalLayout>
  );
}
