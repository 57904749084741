import React, { useEffect, useState } from "react";
import ModalLayout from "../../common/ModalLayout";
import { RescheduleConfig } from "../../../config/rescheduleConfig";
import { FormDropDownList } from "../../../utils/forms/FormComponents";
import { Button } from "@progress/kendo-react-buttons";
import { TIME_SLOTS } from "../AddTaskForm";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";

export default function RescheduleTrip({
  closeModal,
  reasons,
  setReasons,
  handleRescheduleTrip,
}) {
  const getDefaultDate = () => {
    const currentDate = new Date();
    const currentHours = currentDate.getHours();
    if (currentHours >= 22) {
      const nextDate = new Date();
      nextDate.setDate(currentDate.getDate() + 1);
      return nextDate.toISOString().split("T")[0];
    }
    return currentDate.toISOString().split("T")[0];
  };

  const [selectedDate, setSelectedDate] = useState(getDefaultDate());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  useEffect(() => {
    const currentDate = new Date();
    if (currentDate.getHours() >= 22 && selectedDate === currentDate.toISOString().split("T")[0]) {
      const nextDate = new Date();
      nextDate.setDate(currentDate.getDate() + 1);
      setSelectedDate(nextDate.toISOString().split("T")[0]);
    }
  }, [selectedDate]);

 const filterTimeSlots = () => {
    const selectedDateTime = new Date(`${selectedDate}T00:00:00`);
    const currentDateTime = new Date();
    const twoHoursLater = new Date(
      currentDateTime.getTime() + 2 * 60 * 60 * 1000
    );

    if (selectedDateTime.toDateString() === currentDateTime.toDateString()) {
      return TIME_SLOTS.filter((slot) => {
        const slotDateTime = new Date(`${selectedDate}T${slot.value}`);
        return slotDateTime >= twoHoursLater;
      });
    } else if (selectedDateTime > currentDateTime) {
      return TIME_SLOTS;
    }

    return [];
  };


  return (
    <ModalLayout title={"Reschedule Trip"} onClose={closeModal}>
      <div className="tw-w-[400px]">
        <span className="tw-mb-1">Select Reason</span>
        <FormDropDownList
          data={RescheduleConfig.reason}
          textField="label"
          dataItemKey="value"
          value={reasons.reason}
          onChange={(e) =>
            setReasons({
              reason: e.target.value,
              subReason: "",
            })
          }
          style={{ width: "400px" }}
        />
        <span className="tw-mb-1 tw-mt-3 tw-block">Select Sub Reason</span>
        <FormDropDownList
          data={RescheduleConfig.subReason.filter(
            (sub) => sub.reason === reasons.reason.value
          )}
          textField="label"
          dataItemKey="value"
          value={reasons.subReason}
          onChange={(e) =>
            setReasons((prev) => ({
              ...prev,
              subReason: e.target.value,
            }))
          }
          style={{ width: "400px" }}
        />
        <span className="tw-mb-1 tw-mt-3 tw-block">New TimeSlot</span>
        <div className="tw-flex tw-items-center tw-gap-5">
          <Input
            type="date"
            min={getDefaultDate()}
            className="tw-max-w-fit"
            placeholder="Select date"
            value={selectedDate}
            onChange={(e) => {
              setSelectedDate(e.target.value);
            }}
          />
          <DropDownList
            placeholder="Select time slot"
            value={selectedTimeSlot?.label || "Time Slot"}
            data={filterTimeSlots().map((item) => item.label)}
            onChange={(e) => {
              const timeSlotValue = TIME_SLOTS.find(
                (slot) => slot.label === e.target.value
              );

              setSelectedTimeSlot(timeSlotValue);
            }}
          />
        </div>
        <Button
          onClick={() =>
            handleRescheduleTrip({ selectedTimeSlot, selectedDate })
          }
          disabled={!reasons.reason || !reasons.subReason || !selectedTimeSlot}
          className="tw-mt-6"
          themeColor={"primary"}
          type={"button"}
        >
          Reschedule Trip
        </Button>
      </div>
    </ModalLayout>
  );
}
