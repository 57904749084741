export const CANCEL_TRIP_BUTTON = {
  tripStages: [
    "TRIP_INITIATED",
    "SEARCHING_FOR_DRIVER",
    "DRIVER_ALLOCATED",
    "DRIVER_NOT_FOUND",
    "DRIVER_ON_THE_WAY",
    "DRIVER_ARRIVED",
  ],
};

export const RESCHEDULE_TRIP_BUTTON = {
  tripStages: [
    "TRIP_INITIATED",
    "SEARCHING_FOR_DRIVER",
    "DRIVER_ALLOCATED",
    "DRIVER_NOT_FOUND",
    "DRIVER_ON_THE_WAY",
    "DRIVER_ARRIVED",
  ],
};
