import React from "react";
import { convertEnumToLabel, driverStatusTags } from "../../../utils/helpers";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

export default function AssignDriverTable({
  driverProfiles,
  setSelectedDriverController,
  selectedDriverController,
  fitLoading,
}) {
  const columns = [
    // {
    //   id: "driverId",
    //   field: "driverId",
    //   title: "Driver ID",
    //   filterable: false,
    //   filterKey: "driver_id",
    //   filterType: "string",
    //   defaultFilterOperator: "lk",
    //   width: 120,
    // },
    {
      id: "fullName",
      field: "fullName",
      title: "Driver Name",
      filterable: false,
      filterKey: "full_name",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 250,
    },
    {
      id: "mobileNumber",
      field: "mobileNumber",
      title: "Mobile Number",
      filterable: false,
      filterKey: "mobile_number",
      filterType: "string",
      defaultFilterOperator: "lk",
      width: 200,
    },
    {
      id: "distance",
      field: "distance",
      title: "Distance",
      filterable: false,
      width: 150,
      cell: (cell) => (
        <td
          colSpan={cell.colSpan}
          role="gridcell"
          aria-colindex={cell.ariaColumnIndex}
          aria-selected="false"
          datagridcolindex={cell.columnIndex}
        >
          {cell?.dataItem?.distance &&
            Math.round(cell?.dataItem?.distance * 100) / 100 + " km"}
        </td>
      ),
    },
    {
      id: "driverStatus",
      field: "driverStatus",
      title: "Status",
      filterable: false,
      width: 200,
      cell: (cell) => (
        <td
          colSpan={cell.colSpan}
          role="gridcell"
          aria-colindex={cell.ariaColumnIndex}
          aria-selected="false"
          datagridcolindex={cell.columnIndex}
        >
          {driverStatusTags(cell?.dataItem?.driverStatus)}
        </td>
      ),
    },
    {
      id: "allocationFit",
      field: "allocationFit",
      title: "Fit",
      filterable: false,
      width: 250,
      cell: (cell) => (
        <td
          colSpan={cell.colSpan}
          role="gridcell"
          aria-colindex={cell.ariaColumnIndex}
          aria-selected="false"
          datagridcolindex={cell.columnIndex}
        >
          {fitLoading ? (
            <span className="tw-animate-pulse tw-black/60">loading..</span>
          ) : (
            convertEnumToLabel(cell?.dataItem?.allocationFit)
          )}
        </td>
      ),
    },
  ];

  const handleRowClick = (e) => {
    setSelectedDriverController(e.dataItem);
  };

  const rowRender = (row, rowProps) => {
    const isSelected =
      rowProps.dataItem.driverId === selectedDriverController?.driverId;
    const rowStyle = isSelected
      ? { backgroundColor: "#00000060", color: "white" }
      : {};

    return React.cloneElement(
      row,
      {
        style: rowStyle,
      },
      row.props.children
    );
  };

  return (
    <div className="tw-mt-10">
      <Grid
        sortable={false}
        onRowClick={handleRowClick}
        data={driverProfiles}
        rowRender={rowRender}
      >
        {columns.map((column) => (
          <Column
            key={column.id}
            field={column.field}
            title={column.title}
            width={column.width}
            cell={column.cell}
          />
        ))}
      </Grid>
    </div>
  );
}
