import React, { useEffect, useState } from "react";
// import DriverOnlineActivityTable from "./DriverOnlineActivityTable";
import {
  formatDate,
  prefixZeroToValue,
  showErrorMessage,
} from "../../../utils/helpers";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import DriverSessionAccordion, { ActivityCard } from "./DriverSessionAccordion";
import DriverViewMap from "../../maps/DriverViewMap";
import { APIConfig } from "../../../utils/constants/api.constants";
import axiosService from "../../../init/axios";
import LoadingPanel from "../../common/LoadingPanel";
import { defaultActivityState, defaultDriverData } from "../../maps/defaults";
import DriverAccordianNew from "./DriverAccordianNew";
import ToggleSwitch from "../../common/Components";

const DriverOnlineActivity = () => {
  const { driverId } = useParams();
  const [actualPathToggle, setActualPathToggle] = useState(false);
  const date = new Date();
  const currentDate = `${date.getFullYear()}-${prefixZeroToValue(
    date.getMonth() + 1
  )}-${prefixZeroToValue(date.getDate())}`;
  const [calendarDate, setCalendarDate] = useState(currentDate);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activityLogState, setActivityLogState] = useState(
    defaultActivityState.CURRENT
  );
  const [openChild, setOpenChild] = useState(null);

  const [currentOnlineTime, setCurrentOnlineTime] = useState(""); // just for edge case of today's upcoming

  const [driverData, setDriverData] = useState(defaultDriverData);
  const [driverDataUpcoming, setDriverDataUpcoming] = useState([]);
  const [searchParams] = useSearchParams();

  const onCalendarValueChange = (event) => {
    if (!event.target.value) return null;
    setCalendarDate(event.target.value);
  };
  const [selectedSessionAccordionIndex, setSelectedSessionAccordionIndex] =
    useState(-1);

  const getDriverTimelineData = async (driverId, date) => {
    setLoading(true);
    setSelectedSessionAccordionIndex(-1);
    setSelectedTrip(null);
    const isUpcoming =
      currentDate < date ||
      (currentDate === date &&
        activityLogState !== defaultActivityState.CURRENT);
    const endpoint = isUpcoming
      ? APIConfig.driverManagement.getDriverUpcomingTimeline(driverId, date)
      : APIConfig.driverManagement.getDriverTimeline(driverId, date);

    const baseUrl = process.env.REACT_APP_DRIVER_BASE_URL + endpoint;

    try {
      const response = await axiosService.get(baseUrl);

      if (response?.data?.driverSessions) {
        setDriverData(response.data);
        setDriverDataUpcoming([]);
      } else {
        setDriverData(defaultDriverData);
        setDriverDataUpcoming(response.data);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDriverData(defaultDriverData);
      showErrorMessage(error);
    }
  };

  useEffect(() => {
    getDriverTimelineData(driverId, calendarDate);
  }, [calendarDate, activityLogState]);
  useEffect(() => {
    if (!driverData?.driverSessions) return;
    if (calendarDate === currentDate) {
      if (activityLogState === defaultActivityState.UPCOMING) return;
      setCurrentOnlineTime(
        calculateTotalWorkedTime(driverData?.driverSessions)
      );
    }
  }, [calendarDate, driverData, activityLogState]);

  const getSelectedTrip = (trip) => {
    setSelectedTrip(null);
    setSelectedTrip(trip);
  };

  const handleCalendar = () => {
    document.getElementById("calendar").showPicker();
  };

  const hasDriverSessions = driverData?.driverSessions.length > 0;
  const hasDriverUpcomingTrips =
    !hasDriverSessions && driverDataUpcoming.length > 0;

  return (
    <div className="driver-online-activity-div tw-relative">
      <span
        className="k-icon k-i-arrow-left"
        onClick={() => navigate("/home/driver")}
      ></span>

      <div className="tw-flex tw-text-sm tw-mt-5 tw-mb-2.5 tw-justify-between tw-items-center">
        <div className="tw-flex tw-items-center tw-gap-3">
          <img
            className="tw-rounded-full"
            height={60}
            width={60}
            src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8YXZhdGFyfGVufDB8fDB8fHww"
            alt="user-avatar"
          />
          <div>
            <div className="tw-text-base">
              {searchParams?.get("driverName") || ""}
            </div>
            <>
              {calendarDate <= currentDate && driverData?.driverSessions && (
                <div className="tw-font-semibold tw-text-sm">
                  <>
                    {calendarDate === currentDate &&
                    activityLogState === defaultActivityState.UPCOMING ? (
                      <>{currentOnlineTime}</>
                    ) : (
                      currentDate >= calendarDate &&
                      calculateTotalWorkedTime(driverData?.driverSessions)
                    )}
                  </>
                </div>
              )}
            </>
          </div>
        </div>
        <div className="tw-relative">
          <input
            type="date"
            name="calendar"
            id="calendar"
            required
            value={calendarDate}
            onChange={onCalendarValueChange}
            className="tw-opacity-0 tw-pointer-events-none customDatePicker"
          />
          <label
            className="custom-border-primary tw-rounded-md tw-cursor-pointer tw-px-3 tw-py-1.5"
            htmlFor="calendar"
            onClick={handleCalendar}
          >
            {formatDate(calendarDate)}
          </label>
        </div>
      </div>

      <div className="tw-flex tw-gap-5">
        <div className="tw-overflow-y-scroll tw-max-w-lg tw-pr-5 tw-w-full">
          <div className="tw-flex tw-bg-white tw-sticky tw-top-0 tw-p-2 tw-z-10 tw-gap-5 tw-justify-between tw-items-center tw-mb-5">
            <span className="tw-block tw-font-semibold">Activity Logs</span>
            {currentDate == calendarDate && (
              <div className="tw-rounded-md tw-bg-primaryBorder tw-p-1 tw-gap-1 tw-flex tw-items-center">
                <button
                  onClick={() =>
                    setActivityLogState(defaultActivityState.CURRENT)
                  }
                  className={`tw-rounded-[4px] tw-px-2 tw-py-1 tw-text-sm tw-border-none ${
                    activityLogState === defaultActivityState.CURRENT
                      ? "tw-bg-[#262626] tw-text-white"
                      : "tw-bg-white"
                  }`}
                >
                  Current
                </button>
                <button
                  onClick={() =>
                    setActivityLogState(defaultActivityState.UPCOMING)
                  }
                  className={`tw-rounded-[4px] tw-px-2 tw-py-1 tw-text-sm tw-border-none ${
                    activityLogState === defaultActivityState.UPCOMING
                      ? "tw-bg-black tw-text-white"
                      : "tw-bg-white"
                  }`}
                >
                  Upcoming
                </button>
              </div>
            )}
          </div>
          {loading ? (
            <section className="tw-min-h-[60dvh]">
              <LoadingPanel />
            </section>
          ) : (
            <section className="tw-h-[60dvh]">
              {hasDriverSessions && (
                <>
                  {driverData?.driverSessions?.map((item, index) => {
                    return (
                      <div className="tw-select-none" key={index}>
                        <DriverAccordianNew
                          openChild={openChild}
                          setOpenChild={setOpenChild}
                          isOpen={index === selectedSessionAccordionIndex}
                          sessionDetails={item}
                          setActualPathToggle={setActualPathToggle}
                          getSelectedTrip={getSelectedTrip}
                          selectedTrip={selectedTrip}
                          onClick={() => {
                            setSelectedTrip(null);
                            setOpenChild(null);
                            if (index === selectedSessionAccordionIndex) {
                              setSelectedSessionAccordionIndex(-1);
                            } else {
                              setSelectedSessionAccordionIndex(index);
                            }
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              )}

              {hasDriverUpcomingTrips && (
                <>
                  {driverDataUpcoming?.map((item, index) => {
                    return (
                      <DriverAccordianNew
                        key={index}
                        isUpcoming
                        openChild={openChild}
                        setOpenChild={setOpenChild}
                        index={index}
                        isOpen={index === selectedSessionAccordionIndex}
                        sessionDetails={item}
                        getSelectedTrip={getSelectedTrip}
                        selectedTrip={selectedTrip}
                        onClick={() => {
                          setSelectedTrip(null);
                          setSelectedTrip(item);
                          if (index === selectedSessionAccordionIndex) {
                            setSelectedSessionAccordionIndex(-1);
                            setSelectedTrip(null);
                          } else {
                            setSelectedSessionAccordionIndex(index);
                          }
                        }}
                      />
                    );
                  })}
                </>
              )}

              {!hasDriverSessions && !hasDriverUpcomingTrips && (
                <div className="tw-py-1 tw-text-lg">No Data</div>
              )}
            </section>
          )}
        </div>
        <div className="tw-bg-primaryBorder tw-relative tw-w-full tw-h-[60dvh]">
          {(hasDriverSessions || hasDriverUpcomingTrips) && (
            <>
              {selectedTrip && selectedTrip.stage === "TRIP_COMPLETED" && (
                <div className="tw-absolute tw-z-[1] tw-bottom-3.5 tw-right-2.5 tw-rounded-md tw-flex tw-gap-2.5 tw-bg-gray-50 tw-shadow-md tw-backdrop-blur-sm tw-px-2.5 pt-2 pb-1 tw-text-sm custom-border-primary">
                  <span className="tw-block">Actual Path</span>
                  <div>
                    <ToggleSwitch
                      checked={actualPathToggle}
                      onChange={(e) => setActualPathToggle(e.target.checked)}
                    />
                  </div>
                </div>
              )}
              <DriverViewMap
                setActualPathToggle={setActualPathToggle}
                actualPathToggle={actualPathToggle}
                driverId={driverId}
                selectedTrip={selectedTrip}
                currentDriverStatus={driverData?.currentDriverLocation}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DriverOnlineActivity;

function calculateTotalWorkedTime(sessions) {
  const totalWorkedTimeInMinutes = sessions.reduce((total, session) => {
    const sessionStartTime = new Date(session.sessionStartTime);
    const sessionEndTime = session.sessionEndTime
      ? new Date(session.sessionEndTime)
      : new Date();
    const sessionDuration = (sessionEndTime - sessionStartTime) / (1000 * 60);
    return total + sessionDuration;
  }, 0);

  const hours = Math.floor(totalWorkedTimeInMinutes / 60);
  const minutes = Math.floor(totalWorkedTimeInMinutes % 60);
  const hourText = hours === 1 ? "hour" : "hours";
  const minuteText = minutes === 1 ? "minute" : "minutes";

  if (hours > 0 && minutes > 0) {
    return `Online time: ${hours} ${hourText} and ${minutes} ${minuteText}`;
  } else if (hours > 0) {
    return `Online time: ${hours} ${hourText}`;
  } else {
    return `Online time: ${minutes} ${minuteText}`;
  }
}
