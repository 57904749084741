import React, { useEffect, useState } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import CandidateDrivers from "./trip-details-panel/candidate-drivers/CandidateDrivers";
import PaymentLogs from "./trip-details-panel/payment-logs/PaymentLogs";
import InsuranceDetails from "./trip-details-panel/insurance-details/InsuranceDetails";
import TripStatusLogs from "./trip-details-panel/trip-status-logs/TripStatusLogs";
import AllocationLogs from "./trip-details-panel/allocation-logs/AllocationLogs";
import Pricing from "./trip-details-panel/pricing/Pricing";
import axiosService from "../../../init/axios";
import { APIConfig } from "../../../utils/constants/api.constants";
import {
  conditionalRendering,
  convertEnumToLabel,
  replaceNullValues,
  showErrorMessage,
  showToastMessage,
  timeConverter,
} from "../../../utils/helpers";
import LoadingPanel from "../../common/LoadingPanel";
import Waivers from "./trip-details-panel/waivers/Waivers";
import ReserveDrivers from "./trip-details-panel/reserve-drivers/ReserveDrivers";
import TripInvoices from "./trip-details-panel/trip-invoices/TripInvoices";
import RevenueStream from "./trip-details-panel/revenue-stream/RevenueStream";
import B2BTripManagementPage from "../../../pages/trip-management/B2BTripManagementPage";
import B2CTripManagementPage from "../../../pages/trip-management/B2CTripManagementPage";
import CardLayout from "../../common/CardLayout";
import { Button } from "@progress/kendo-react-buttons";
import RescheduleTrip from "./RescheduleTrip";
import CancelTrip from "./CancelTrip";
import {
  CANCEL_TRIP_BUTTON,
  RESCHEDULE_TRIP_BUTTON,
} from "../../../config/statusPermissions";

const customStyle = {
  padding: "15px",
};

const initialReasons = { reason: "", subReason: "" };
const initialModal = { cancelledModal: false, rescheduleModal: false };

const TripDetailPage = ({ setTripDetailsSelected }) => {
  const { tripId } = useParams();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState([""]);
  const [tripDetails, setTripDetails] = useState();
  const [refreshTripDetails, setRefreshTripDetails] = useState(false);
  const [refreshTripCalled, setRefreshTripCalled] = useState(false);
  const [loading, setLoading] = useState("");
  let tripEditUrl =
    process.env.REACT_APP_TRIP_BASE_URL +
    APIConfig.tripManagement.rescheduleTrip(tripId);
  const [modalOpen, setModalOpen] = useState(initialModal);

  const [reasons, setReasons] = useState(initialReasons);

  useEffect(() => {
    //API call to fetch trip details
    getTripDetails();
  }, [tripId]);

  useEffect(() => {
    if (refreshTripCalled) {
      getTripDetails();
    }
  }, [refreshTripDetails]);

  const getTripDetails = async () => {
    setLoading(true);
    await axiosService
      .get(
        process.env.REACT_APP_TRIP_BASE_URL_NEW +
          APIConfig.tripManagement.getTripDetails(tripId)
      )
      .then((data) => {
        setLoading(false);
        setTripDetails(data.data);
      })
      .catch((error) => {
        setLoading(false);
        showErrorMessage(error);
      });
  };
  const handleSelect = (event) => {
    if (event.expandedItems) {
      setExpanded(event.expandedItems);
    }
  };
  const closeModal = () => {
    setModalOpen({ cancelledModal: false, rescheduleModal: false });
    setReasons(initialReasons);
  };
  const onGoBack = () => {
    setTripDetailsSelected(false);
    navigate("/home/trip");
  };

  const allowedClientTypes = ["B2B", "C2B"];
  const allowedStages = ["SEARCHING_FOR_DRIVER"];

  const isAllowed =
    allowedClientTypes.includes(tripDetails?.clientType) &&
    allowedStages.includes(tripDetails?.stage);

  const handleRescheduleTrip = (slot) => {
    // if (!reasons.reason || !slot.selectedDate || !slot.selectedTimeSlot)
    // return showErrorMessage("Add Required Details");
    let payload = {
      action: "RESCHEDULE",
      actionDetails: {
        actionBy: "",
        reason: reasons.reason?.value,
        subReason: reasons.subReason?.value || "",
        pickupSlotStartTime: `${slot.selectedDate} ${slot.selectedTimeSlot.value}`,
      },
    };
    axiosService
      .put(tripEditUrl, payload)
      .then((e) => {
        setModalOpen(initialModal);
        navigate(`/home/trip/${e.data.tripId}/details`);
        showToastMessage("Trip Rescheduled Successfully");
      })
      .catch((err) => console.log(err));
  };

  const handleCancelTrip = (email) => {
    let payload = {
      action: "CANCEL",
      actionDetails: {
        actionBy: email,
        reason: reasons.reason?.value,
        subReason: reasons.subReason?.value || "",
      },
    };
    axiosService
      .put(tripEditUrl, payload)
      .then((e) => {
        setModalOpen(initialModal);
        navigate(`/home/trips`);
        showToastMessage("Trip Cancelled Successfully");
      })
      .catch((err) => console.log(err));
  };
  return (
    <CardLayout
      title="Trip Management"
      tripDetails={tripDetails}
      ComponentRender={TagsList}
    >
      <div className="trip-details tw-relative tw-min-h-[80dvh]">
        <span className="k-icon k-i-arrow-left" onClick={onGoBack}></span>
        {loading ? (
          <LoadingPanel />
        ) : (
          <>
            <div className="trip-details-header mx-3 my-3">
              <div className="tw-flex tw-items-start tw-justify-between">
                <div className="tw-flex tw-items-start tw-gap-5">
                  <div>
                    <h4 className="tw-text-2xl tw-font-semibold">
                      Trip Id : {tripId}
                    </h4>
                    <p className="tw-text-sm">
                      Booking Created At :{" "}
                      {timeConverter(tripDetails?.tripCreationTime)}
                    </p>
                  </div>
                  <div className="tw-space-x-2">
                    {tripDetails?.tag === "CRITICAL_ZONE" && (
                      <div className="status-pill CANCELLED">
                        <span>Critical</span>
                      </div>
                    )}
                    {tripDetails?.stage && (
                      <div className="status-pill trip_acceptance_timer_running">
                        <span>{convertEnumToLabel(tripDetails?.stage)}</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* cta's */}
                <div className="tw-flex tw-items-center tw-gap-5">
                  {isAllowed && (
                    <>
                      {conditionalRendering(
                        CANCEL_TRIP_BUTTON.tripStages,
                        tripDetails?.stage,
                        <Button
                          rounded={"medium"}
                          themeColor={"error"}
                          onClick={() =>
                            setModalOpen({
                              cancelledModal: true,
                              rescheduleModal: false,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      )}
                      {conditionalRendering(
                        RESCHEDULE_TRIP_BUTTON.tripStages,
                        tripDetails?.stage,
                        <Button
                          rounded={"medium"}
                          themeColor={"dark"}
                          onClick={() =>
                            setModalOpen({
                              cancelledModal: false,
                              rescheduleModal: true,
                            })
                          }
                        >
                          Reschedule
                        </Button>
                      )}
                      <Link
                        className="hover:tw-text-white tw-px-3 tw-py-1.5 tw-rounded-md tw-text-white tw-bg-primary tw-text-sm"
                        to={`/home/trip/${tripId}/assign-driver`}
                      >
                        Assign Driver
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="trip-details-panel mx-3 my-4">
              {tripDetails && tripDetails?.clientType === "B2B" ? (
                <B2BTripManagementPage
                  tripDetails={tripDetails}
                  tripId={tripId}
                />
              ) : (
                <B2CTripManagementPage
                  tripDetails={tripDetails}
                  tripId={tripId}
                />
              )}

              <PanelBar
                expanded={expanded}
                onSelect={handleSelect}
                expandMode={"single"}
              >
                {/* <PanelBarItem title='Customer Details'>
                                <div style={customStyle} className="plate">
                                    <p>Customer Name : <b>{replaceNullValues(tripDetails?.customerDetails?.fullName)}</b></p>
                                    <p>Phone Number : <b>{replaceNullValues(tripDetails?.customerDetails?.mobileNumber)}</b></p>
                                </div>
                            </PanelBarItem>
                            <PanelBarItem title='Trip Details'>
                                <div style={customStyle} className="custom-template">
                                    <TripDetails tripDetails={tripDetails} />
                                </div>
                            </PanelBarItem> */}
                <PanelBarItem title="Candidate Drivers">
                  <div style={customStyle} className="custom-template">
                    <CandidateDrivers
                      tripId={tripId}
                      lat={
                        tripDetails?.expectedSourceLocation?.lat &&
                        tripDetails?.expectedSourceLocation?.lat
                      }
                      lng={
                        tripDetails?.expectedSourceLocation?.lng &&
                        tripDetails?.expectedSourceLocation?.lng
                      }
                      tripBookingMode={tripDetails?.tripBookingMode}
                      stage={tripDetails?.stage}
                    />
                  </div>
                </PanelBarItem>
                {!tripDetails?.b2bTripFareDetails ? (
                  <PanelBarItem title="Pricing">
                    <div style={customStyle} className="custom-template">
                      <Pricing
                        tripId={tripId}
                        journeyMode={tripDetails?.journeyMode}
                        tripType={tripDetails?.derivedTripType}
                        selectedDuration={tripDetails?.selectedDuration}
                        selectedDurationUnit={tripDetails?.selectedDurationUnit}
                        actualTripStartTime={tripDetails?.actualTripStartTime}
                        actualTripEndTime={tripDetails?.actualTripEndTime}
                        couponCode={tripDetails?.couponCode}
                      />
                    </div>
                  </PanelBarItem>
                ) : (
                  <PanelBarItem title="Pricing">
                    <div style={customStyle} className="custom-template">
                      <div className="custom-border-primary tw-p-4 tw-rounded-md">
                        <div className="tw-mt-4 tw-flex-col tw-gap-8">
                          <div>
                            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                              Actual Trip Distance (km)
                            </p>
                            <p>
                              {tripDetails?.tripPathDetails?.actualDistance ||
                                "N.A."}
                            </p>
                          </div>
                          <div>
                            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                              Trip Base Rate
                            </p>
                            <p>
                              {tripDetails?.b2bTripFareDetails?.baseRate ||
                                "N.A."}
                            </p>
                          </div>
                          <div>
                            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                              Night Charges
                            </p>
                            <p>
                              {tripDetails?.b2bTripFareDetails?.nightCharges ||
                                "N.A."}
                            </p>
                          </div>
                          <div>
                            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                              Total Fare
                            </p>
                            <p>
                              {tripDetails?.b2bTripFareDetails?.totalFare ||
                                "N.A."}
                            </p>
                          </div>
                          {/* <div>
                            <p className="tw-text-[#919191] tw-mb-0 tw-text-sm">
                              Config Version
                            </p>
                            <p>
                              {tripDetails?.b2bTripFareDetails?.configVersion ||
                                "N.A."}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </PanelBarItem>
                )}
                <PanelBarItem title="Revenue Stream">
                  <div style={customStyle} className="custom-template">
                    <RevenueStream tripId={tripId} />
                  </div>
                </PanelBarItem>
                <PanelBarItem title="Trip Invoices">
                  <div style={customStyle} className="custom-template">
                    <TripInvoices tripId={tripId} stage={tripDetails?.stage} />
                  </div>
                </PanelBarItem>
                <PanelBarItem title="Reserve Drivers">
                  <div style={customStyle} className="custom-template">
                    <ReserveDrivers
                      tripId={tripId}
                      lat={
                        tripDetails?.expectedSourceLocation?.lat &&
                        tripDetails?.expectedSourceLocation?.lat
                      }
                      lng={
                        tripDetails?.expectedSourceLocation?.lng &&
                        tripDetails?.expectedSourceLocation?.lng
                      }
                      tripBookingMode={tripDetails?.tripBookingMode}
                      stage={tripDetails?.stage}
                      refreshTripDetails={refreshTripDetails}
                      setRefreshTripDetails={setRefreshTripDetails}
                      refreshTripCalled={refreshTripCalled}
                      setRefreshTripCalled={setRefreshTripCalled}
                    />
                  </div>
                </PanelBarItem>
                <PanelBarItem title="Payment Logs">
                  <div style={customStyle} className="custom-template">
                    <PaymentLogs tripId={tripId} stage={tripDetails?.stage} />
                  </div>
                </PanelBarItem>
                <PanelBarItem title="Insurance Details">
                  <div style={customStyle} className="custom-template">
                    <InsuranceDetails
                      tripId={tripId}
                      insuranceOpted={tripDetails?.insuranceOpted}
                    />
                  </div>
                </PanelBarItem>
                <PanelBarItem title="Trip Status Logs">
                  <div style={customStyle} className="custom-template">
                    <TripStatusLogs tripId={tripId} />
                  </div>
                </PanelBarItem>
                <PanelBarItem title="Allocation Logs">
                  <div style={customStyle} className="custom-template">
                    <AllocationLogs tripId={tripId} />
                  </div>
                </PanelBarItem>
                <PanelBarItem title="Waivers">
                  <div style={customStyle} className="custom-template">
                    <Waivers tripId={tripId} />
                  </div>
                </PanelBarItem>
                {/* {
                                TripConfig.waiverStages.includes(tripDetails?.stage) && (
                                    <PanelBarItem title='Waivers'>
                                        <div style={customStyle} className="custom-template">
                                            <Waivers tripId={tripId} />
                                        </div>
                                    </PanelBarItem>
                                )
                            } */}
              </PanelBar>
            </div>
          </>
        )}
      </div>

      <>
        {modalOpen.rescheduleModal && (
          <RescheduleTrip
            closeModal={closeModal}
            reasons={reasons}
            setReasons={setReasons}
            handleRescheduleTrip={handleRescheduleTrip}
          />
        )}
        {modalOpen.cancelledModal && (
          <CancelTrip
            closeModal={closeModal}
            reasons={reasons}
            setReasons={setReasons}
            handleCancelTrip={handleCancelTrip}
          />
        )}
      </>
    </CardLayout>
  );
};

function TagsList(props) {
  const tripId = props?.tripDetails?.tripId;
  const tripType = props?.tripDetails?.tripType;
  const isB2B = window.location.href.includes("b2b");

  return (
    <div className="tw-flex tw-gap-4 tw-items-center">
      {tripId && (
        <span className="tw-py-1 tw-px-4 tw-bg-blue-50 tw-text-blue-600 tw-text-[12px] tw-rounded-md">
          {tripId}
        </span>
      )}
      {tripType && (
        <span className="tw-py-1 tw-px-4 tw-bg-orange-50 tw-text-orange-600 tw-text-[12px] tw-rounded-md">
          {tripType}
        </span>
      )}
      <span className="tw-py-1 tw-px-4 tw-bg-pink-50 tw-text-pink-600 tw-text-[12px] tw-rounded-md">
        {isB2B ? "B2B" : "B2C"}
      </span>
    </div>
  );
}

export default TripDetailPage;
